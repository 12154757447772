import { Chip, Grid } from '@mui/material';
import React from 'react';
import { diffDays } from '../../../utils/date';
import { useTranslation } from 'react-i18next';

export function ProjectLabels(props) {
  const { project } = props;
  const { t } = useTranslation();

  const startInDays = diffDays(new Date(), project.startDate);
  const projectStarted = new Date() >= new Date(project.startDate);
  const projectEnded = new Date() > new Date(project.endDate);

  return (
    <Grid container alignItems={'center'}>
      <Grid item>
        <Chip
          label={
            project.published
              ? projectEnded
                ? t('project_state_ended')
                : t('project_state_published')
              : t('project_state_draft')
          }
          style={{
            backgroundColor: project.published ? '#05A488' : '#FED961',
            color: project.published ? '#fff' : '#0A0A0A',
          }}
        />
      </Grid>
      {!!project.published && !!project.startDate && !projectEnded && (
        <Grid item marginLeft={1}>
          <Chip
            label={
              projectStarted
                ? t('project_state_live')
                : project.prelaunch
                  ? t('project_state_prelaunch')
                  : t('start_in') + ' ' + startInDays + ' ' + (startInDays > 1 ? t('days') : t('day'))
            }
            style={{
              backgroundColor: '#FED961',
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}
