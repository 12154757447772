class SegmentService {
  constructor() {
    if (!window.analytics) {
      console.error('Segment is not initialized.');
      return;
    }
    this.analytics = window.analytics;
  }

  load() {
    if (this.analytics) {
      this.analytics.load(process.env.REACT_APP_SEGMENT_WRITE_KEY);
      console.debug('Segment is initialized.');
    }
  }

  identify(userId, traits) {
    if (this.analytics) {
      this.analytics.identify(userId, traits);
      console.log(`Identified user ${userId} with traits:`, traits);
    }
  }

  track(event, properties) {
    if (this.analytics) {
      this.analytics.track(event, properties);
      console.log(`Tracked event ${event} with properties:`, properties);
    }
  }

  page(name, properties) {
    if (this.analytics) {
      this.analytics.page(name, properties);
      console.log(`Paged ${name} with properties:`, properties);
    }
  }

  group(groupId, traits) {
    if (this.analytics) {
      this.analytics.group(groupId, traits);
      console.log(`Grouped ${groupId} with traits:`, traits);
    }
  }

  alias(newId, originalId) {
    if (this.analytics) {
      this.analytics.alias(newId, originalId);
      console.log(`Aliased ${newId} from ${originalId}`);
    }
  }
}

export default new SegmentService();
