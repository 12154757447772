import React, { useEffect } from 'react';
import ProjectCard from '../../../components/project/ProjectCard';
import ProjectDataService from '../../../services/project.service';

const ProjectWidget = ({ projectSlug }) => {
  const [projectData, setProjectData] = React.useState(null);

  useEffect(() => {
    ProjectDataService.get(projectSlug).then((res) => {
      setProjectData(ProjectDataService.decrypt([res.data])[0]);
    });
  }, [projectSlug]);

  if (!projectData) return null;

  return <ProjectCard project={projectData} />;
};

export default ProjectWidget;
