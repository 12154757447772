import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import FileInputField from './FileInputField';

const MultiLangField = ({ label, name, value, onChange }) => {
  const locales = Object.keys(value);

  const handleFileChange = (file, locale) => {
    onChange(file, name, locale);
  };

  return (
    <Grid container spacing={2}>
      {locales.map((locale) => (
        <Grid item xs={12 / locales.length} key={locale}>
          <Typography variant='subtitle2'>{`${label} (${locale.toUpperCase()})`}</Typography>
          <FileInputField value={value[locale]} onChange={(e) => handleFileChange(e, locale)} />
        </Grid>
      ))}
    </Grid>
  );
};

MultiLangField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiLangField;
