import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import ProjectDataService from '../../../services/project.service';
import { useTranslation } from 'react-i18next';

const ProjectDashboardWidget = ({ projectSlug }) => {
  const { t } = useTranslation();
  const [projectData, setProjectData] = React.useState(null);
  const [copied, setCopied] = React.useState(false);

  useEffect(() => {
    ProjectDataService.get(projectSlug).then((res) => {
      setProjectData(ProjectDataService.decrypt([res.data])[0]);
    });
  }, [projectSlug]);

  if (!projectData) return null;

  const widgetUrl = `${window.location.origin}/widget/project/${projectData.slug}`;
  const embedCode = `<iframe src="${widgetUrl}" width="400" height="500" style="border-width: 0" id="mm-${projectData.slug}" title="${projectData.title}"></iframe>`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(embedCode);
    setCopied(true);

    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Box flexDirection={'column'} display={'flex'}>
      <Typography textAlign={'center'} sx={{ marginBottom: '1.5rem' }} variant={'h5'} fontWeight={'bold'}>
        {t('widget_title')}
      </Typography>
      <Typography variant={'body1'} textAlign={'center'}>
        {t('widget_preview')}
      </Typography>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} marginTop={'1.5rem'}>
        <TextareaAutosize
          style={{
            borderRadius: '12px 12px 0 12px',
            padding: '12px',
            border: '1px solid black',
            width: '400px',
            userSelect: 'none',
          }}
          defaultValue={embedCode}
          readOnly
          unselectable
        />
        <Button className='btn-primary' onClick={copyToClipboard} sx={{ margin: '1.5rem auto' }}>
          {copied ? t('widget_copied') : t('widget_copy')}
        </Button>
        <div dangerouslySetInnerHTML={{ __html: embedCode }} />
      </Box>
    </Box>
  );
};

export default ProjectDashboardWidget;
