import { Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ProjectDataService from '../../services/project.service';
import ProjectCard from '../project/ProjectCard';

function PopularProjects({ title }) {
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    ProjectDataService.getPopulars({ limit: 4, brand: 'mymoojo' }).then((response) => {
      const tempProjects = ProjectDataService.decrypt(response.data.value);
      setProjects(tempProjects);
    });
  }, []);

  if (!projects || !projects.length) {
    return;
  }

  return (
    <Grid container item xs={12} spacing={0} direction='row' justifyContent='center'>
      <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Grid item xs={9}>
          <h1>{title || t('popular_projects')}</h1>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container className='popularProjects' spacing={3} direction='row'>
          {(projects &&
            projects.map((project, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index} display='flex'>
                <ProjectCard project={project} index={index} />
              </Grid>
            ))) || (
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
              <Grid item xs={9}>
                <p>{t('no_project_found')}</p>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={10}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: '40px',
        }}
      >
        <Button id='btnAllLearning' onClick={() => navigate('/search')} className='btn-primary'>
          {t('show_all_learning')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default PopularProjects;
