import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SDGBanner = ({ sdgs, style }) => {
  const { t, i18n } = useTranslation();

  if (!sdgs || sdgs.length === 0) {
    return null;
  }

  const currentLanguage = i18n.language;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '16px',
        padding: '1.5rem',
        backgroundColor: '#fafafa',
        border: '1px solid #0000000D',
        borderRadius: '10px',
        ...style,
      }}
    >
      <Typography
        sx={{
          marginBottom: '24px',
          color: '#333',
          fontSize: '19px',
          fontWeight: 'bold',
          lineHeight: '24px',
        }}
        dangerouslySetInnerHTML={{
          __html: t('impact.sdg.title', { count: sdgs.length, plural: sdgs.length > 1 ? 's' : '' }),
        }}
      />

      {sdgs.map((item) => (
        <Box
          key={item.id}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: '20px',
          }}
        >
          <img
            src={item.images[currentLanguage] || item.images.en}
            alt={item.name[currentLanguage] || item.name.en}
            style={{
              height: '80px',
              width: '80px',
              marginRight: '15px',
              borderRadius: '5px',
            }}
          />
          <Box>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '600',
              }}
            >
              {item.name[currentLanguage] || item.name.en}
            </Typography>
            <Typography variant='body2'>{item.description[currentLanguage] || item.description.en}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default SDGBanner;
