import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@mui/material';

const MultiLangField = ({ label, name, value, onChange }) => {
  const locales = Object.keys(value);

  return (
    <Grid container spacing={2}>
      {locales.map((locale) => (
        <Grid item xs={12 / locales.length} key={locale}>
          <TextField
            label={`${label} (${locale.toUpperCase()})`}
            name={`${name}.${locale}`}
            value={value[locale] || ''}
            onChange={onChange}
            fullWidth
          />
        </Grid>
      ))}
    </Grid>
  );
};

MultiLangField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiLangField;
