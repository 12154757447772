import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PartnershipsProjectBanner = ({ partnerships }) => {
  const { t } = useTranslation();

  if (!partnerships || partnerships.length === 0) {
    return null;
  }

  const partner = partnerships[0]; // Assuming we use the first partner
  const { contribution_amount, double_donation } = partner.project_partnership;

  const formatAmount = (amount) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0'); // Ajoute un espace insécable
  };

  return (
    <div style={{ backgroundColor: '#F2EEE9', boxShadow: '#1c1c1c1c 0px 3px 10px 0px inset' }}>
      <div className='container'>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} paddingY={2}>
          <Typography variant='h7' sx={{ marginRight: '10px', fontWeight: 400, fontSize: '16px' }}>
            {t('project.supported_by')}
          </Typography>
          <div>
            <a href={partner.website || '#'} target='_blank' rel='noopener noreferrer'>
              <img src={partner.logo} alt={partner.name} style={{ height: '14px', marginRight: '16px' }} />
            </a>
          </div>
          <div
            style={{
              backgroundColor: '#FFF3F9',
              borderRadius: '8px',
              padding: '4px 8px',
              color: '#FF86C3',
              fontWeight: '700',
              fontSize: '14px',
              transform: 'rotate(-1deg)',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >
            {double_donation ? 'Dons doublés' : `+${formatAmount(contribution_amount)}€`}
          </div>
        </Box>
      </div>
    </div>
  );
};

export default PartnershipsProjectBanner;
