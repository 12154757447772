import React from 'react';
import Grid from '@mui/material/Grid';
import { Container, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProjectCard from './ProjectCard';

function ProjectsList(props) {
  const { t } = useTranslation();
  const { brand } = props;

  return (
    <Container maxWidth='xl'>
      <Grid container marginTop={1} marginBottom={5} spacing={3} alignItems='stretch'>
        {props.projects &&
          props.projects.map((project, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index} display='flex'>
              <ProjectCard project={project} brand={brand} index={index} />
            </Grid>
          ))}
        <Grid
          container
          item
          direction='column'
          justifyContent='space-between'
          xs={12}
          sm={6}
          md={4}
          lg={3}
          minHeight={400}
        >
          <Grid
            style={{
              position: 'relative',
              textAlign: 'center',
              flex: '1',
              background: 'rgba(250, 250, 250, 1) 0% 0% no-repeat padding-box',
            }}
            border={1}
            borderColor={'rgba(0, 0, 0, 0.05)'}
            p={4}
            sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff' }}
            borderRadius={3}
          >
            <h3>{t('dont_found_formation')}</h3>
            <p>{t('ask_new_formation')}</p>
            <Button
              className='btn-primary'
              onClick={() => window.open('https://tally.so/r/w2vl4e', '_blank', 'noopener,noreferrer')}
              style={{
                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
                marginTop: 'auto',
              }}
            >
              {t('contact_us')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ProjectsList;
