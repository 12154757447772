import React from 'react';
import { Typography, LinearProgress, Box, Chip, Card, CardMedia, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProjectCardPlaceholder from '../../assets/images/project-card-placeholder.svg';
import { Link } from 'react-router-dom';
import i18n from '../../i18n';

const ProjectCard = ({ project, isClickable = true }) => {
  const { t } = useTranslation();

  const getActualDonations = () => {
    return Number(project?.totalPayments ?? 0);
  };

  const getContributors = () => project?.totalContributors ?? 0;
  const normalise = (value, maximum) => ((value * 100) / (maximum || 1)).toFixed(0);

  const goalPercentage = Number(
    normalise(project.goal_type === 'target_sale' ? getContributors() : getActualDonations(), project.goal),
  );

  const remainingDays = () => {
    const endDate = new Date(project.endDate);
    endDate.setHours(0, 0, 0, 0);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const diff = endDate.getTime() - today.getTime();
    return Math.max(0, Math.floor(diff / (1000 * 3600 * 24)));
  };

  const prelaunch = project.prelaunch && new Date(project.startDate) > new Date();
  const inProgress = !prelaunch && new Date(project.endDate) > new Date();
  const ended = !prelaunch && !inProgress;
  const success = goalPercentage >= 100;
  const endedGoalReached = remainingDays() === 0 && success;
  const endedGoalNotReached = remainingDays() === 0 && !success;
  const inProgressGoalReached = remainingDays() > 0 && success;
  const inProgressGoalNotReached = remainingDays() > 0 && !success;

  const progressColor = prelaunch
    ? '#4a4a4a'
    : endedGoalReached
      ? '#05A488'
      : endedGoalNotReached
        ? '#B7B7B7'
        : inProgressGoalReached
          ? '#E05D9C'
          : inProgressGoalNotReached
            ? '#E05D9C'
            : '#6A6A6A';

  const progressValue = goalPercentage > 100 ? 100 : prelaunch ? 100 : goalPercentage;

  const partnershipSticker = project?.partnerships?.[0]?.sticker || null;
  const locale = i18n.language.toLowerCase() ? i18n.language.toLowerCase() : navigator.language;

  const renderChip = (label) => (
    <Chip
      label={t(label)}
      sx={{
        position: 'absolute',
        top: prelaunch ? '-15px' : '-12px',
        left: '24px',
        fontSize: '0.8rem',
        borderRadius: 1,
        backgroundColor: progressColor,
        color: '#fff',
        fontWeight: '600',
        height: 27,
        padding: '0 5px',
        letterSpacing: 0.5,
      }}
    />
  );

  return (
    <Card
      component={isClickable ? Link : 'div'}
      to={`/project/${project.slug}`}
      sx={{
        borderRadius: 3,
        boxShadow: 'rgba(99, 99, 99, 0.15) 0px 0px 1.7rem',
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        cursor: isClickable ? 'pointer' : 'default',
        textDecoration: 'none',
        color: 'inherit',
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <CardMedia component='img' height='180' image={project.image || ProjectCardPlaceholder} alt={project.title} />
        {project.taxDeductible && (
          <Box className='project-label success-project-label'>{t('project_tax_deductible')}</Box>
        )}
        {partnershipSticker && (
          <Box sx={{ position: 'absolute', top: 0, right: 15 }}>
            <img src={partnershipSticker} alt='Partnership Logo' style={{ width: 85, height: 85 }} />
          </Box>
        )}
        <Box sx={{ position: 'relative' }}>
          <LinearProgress
            variant='determinate'
            value={progressValue}
            sx={{
              height: 5,
              mx: 0,
              backgroundColor: 'transparent',
              '& .MuiLinearProgress-bar': {
                backgroundColor: progressColor,
                borderRadius: success ? '0px' : '8px',
              },
              visibility: prelaunch ? 'hidden' : 'visible',
            }}
          />

          {(prelaunch && renderChip('project_state_prelaunch')) ||
            (endedGoalReached && renderChip(t('project_state_financed_percent', { percent: goalPercentage })))}
        </Box>
      </Box>
      <CardContent
        sx={{ flexGrow: 1, padding: 3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
      >
        <Box sx={{ minHeight: '3rem' }}>
          <Typography component='h4' sx={{ fontWeight: 'bold', lineHeight: 1.2, color: '#1C1C1C' }} gutterBottom>
            {project.title}
          </Typography>
          <Typography sx={{ fontSize: '.8rem', fontWeight: '600', color: '#05A488' }}>
            {project.user.firstName} {project.user.lastName}
          </Typography>
        </Box>
        <Box mt={2}>
          <Box display='flex' justifyContent='space-between'>
            {(prelaunch && (
              <Box textAlign='left'>
                <Typography
                  sx={{
                    fontSize: '0.8rem',
                    fontWeight: '600',
                  }}
                >
                  {new Date(project.startDate).toLocaleDateString(locale)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '0.7rem',
                    fontWeight: '300',
                    lineHeight: 1,
                  }}
                >
                  {t('launch_date')}
                </Typography>
              </Box>
            )) || (
              <>
                <Box textAlign='left'>
                  <Typography
                    sx={{
                      fontSize: '0.8rem',
                      fontWeight: '600',
                    }}
                  >
                    {getContributors()}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '0.7rem',
                      fontWeight: '300',
                      lineHeight: 1,
                    }}
                  >
                    {t(getContributors() > 1 ? 'card_contributions' : 'card_contribution')}
                  </Typography>
                </Box>
                {(ended && (
                  <Box textAlign='left'>
                    <Typography
                      sx={{
                        fontSize: '0.8rem',
                        fontWeight: '600',
                      }}
                    >
                      {project.endDate ? new Date(project.endDate).toLocaleDateString(locale) : 'N/A'}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '0.7rem',
                        fontWeight: '300',
                        lineHeight: 1,
                      }}
                    >
                      {t('end_date_lower')}
                    </Typography>
                  </Box>
                )) || (
                  <Box textAlign='left'>
                    <Typography
                      sx={{
                        fontSize: '0.8rem',
                        fontWeight: '600',
                      }}
                    >
                      {remainingDays()} {t(remainingDays() > 1 ? 'days' : 'day')}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '0.7rem',
                        fontWeight: '300',
                        lineHeight: 1,
                      }}
                    >
                      {t(remainingDays() > 1 ? 'days_left' : 'day_left')}
                    </Typography>
                  </Box>
                )}
                <Box textAlign='left'>
                  <Typography
                    sx={{
                      fontSize: '0.8rem',
                      fontWeight: '600',
                    }}
                  >
                    {project.goal_type === 'target_sale'
                      ? `${getContributors().toLocaleString('fr-FR', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })} ${getContributors() > 1 ? t('preorders') : t('preorder')}`
                      : `${getActualDonations().toLocaleString('fr-FR', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })} €`}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '0.7rem',
                      fontWeight: '300',
                      lineHeight: 1,
                    }}
                  >
                    {t('on')} {project.goal?.toLocaleString('fr-FR') ?? '0'}{' '}
                    {project.goal_type !== 'target_sale' ? '€' : ''}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
          <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'nowrap', justifyContent: 'space-between', mt: 2 }}>
            <Box sx={{ display: 'flex', gap: 0.5, overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {project.projectCategories.length > 0 ? (
                project.projectCategories
                  .slice(0, 2)
                  .map((cat, idx) => (
                    <Chip
                      key={idx}
                      label={cat.category.name}
                      sx={{ backgroundColor: '#F2EEE9', borderRadius: 1, fontSize: '0.6rem', fontWeight: '400' }}
                      size='small'
                    />
                  ))
              ) : (
                <Box sx={{ width: '100%' }} />
              )}
            </Box>
            {!prelaunch && (
              <Chip
                label={t('project_card_goal_percent', { goalPercentage: goalPercentage ?? 0 })}
                sx={{
                  backgroundColor: inProgress ? '#FFF3F9' : endedGoalReached ? '#E9F4EF' : '#F2EEE9',
                  color: inProgress ? '#FF86C3' : endedGoalReached ? '#05A488' : '#939393',
                  borderRadius: 1,
                  fontSize: '0.6rem',
                  fontWeight: '400',
                }}
                size='small'
              />
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProjectCard;
