import React, { useState } from 'react';
import {
  Button,
  FormControl,
  OutlinedInput,
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Box,
  Backdrop,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ProjectDataService from '../../../services/project.service';
import SuccessIcon from '../../../assets/images/success.svg';
import AvatarsGroup from '../../../assets/images/avatars-group.svg';
import BackgroundImage from '../../../assets/images/project-background-cover.jpg';

const PrelaunchSubscription = ({ project }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);

  const subscribeToLaunch = async () => {
    if (!email.trim() || !email.includes('@') || !email.includes('.')) {
      toast.error(t('error.invalid_email'));
      return;
    }

    try {
      await ProjectDataService.subscribe({ email, projectId: project.id });
      setEmail('');
      setSubscribed(true);
    } catch (e) {
      const status = e?.response?.status;
      switch (status) {
        case 400:
          toast.error(t('error.invalid_request'));
          break;
        case 404:
          toast.error(t('error.project_not_found'));
          break;
        case 409:
          toast.error(t('error.already_subscribed'));
          break;
        default:
          toast.error(t('error.general'));
      }
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopied(true);

    setTimeout(() => setCopied(false), 2000);
  };

  const CustomBackdrop = (props) => (
    <Backdrop
      {...props}
      sx={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    />
  );

  return (
    <Dialog
      open
      fullWidth
      slots={{ backdrop: CustomBackdrop }}
      maxWidth='md'
      transitionDuration={0}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '10px',
          width: { xs: '90%', sm: '70%', md: '50%' },
          maxWidth: '600px',
        },
      }}
    >
      <DialogContent
        sx={{
          textAlign: 'center',
          padding: { xs: '2rem', sm: '3rem 4rem' },
          backgroundColor: '#fff',
        }}
      >
        <IconButton
          aria-label='close'
          onClick={() => (window.history.length > 1 ? navigate(-1) : navigate('/search'))}
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            width: { xs: '30px', sm: '40px' },
            height: { xs: '30px', sm: '40px' },
          }}
        >
          <CloseIcon sx={{ fontSize: { xs: '20px', sm: '24px' } }} />
        </IconButton>
        {!subscribed ? (
          <>
            <Typography>{t('prelaunch_modal_title', { firstName: project.user.firstName })}</Typography>
            <Typography fontWeight='bold' sx={{ fontSize: { xs: '16px', sm: '18px' } }}>
              {t('prelaunch_modal_title_project', { projectName: project.title })}
            </Typography>
            <Typography fontWeight='bold' sx={{ marginTop: '40px', fontSize: '18px' }}>
              {t('prelaunch_modal_description_title')}
            </Typography>
            <Typography sx={{ marginBottom: '20px', fontStyle: 'italic', fontSize: { xs: '12px', sm: '14px' } }}>
              {t('prelaunch_modal_description_info')}
            </Typography>
            <FormControl required fullWidth sx={{ margin: '10px 0' }}>
              <OutlinedInput
                sx={{
                  backgroundColor: '#f9f9f9',
                  padding: '.5rem',
                  fontSize: { xs: '14px', sm: '16px' },
                }}
                id='prelaunch_email'
                name='prelaunch_email'
                value={email}
                placeholder={t('prelaunch_modal_email_placeholder')}
                onChange={(e) => setEmail(e.target.value)}
                inputProps={{ type: 'email' }}
              />
            </FormControl>
            <Button
              className='btn-primary'
              onClick={subscribeToLaunch}
              disabled={!email.trim()}
              sx={{
                width: '100%',
                maxWidth: '300px',
                margin: '10px auto',
                fontSize: { xs: '14px', sm: '16px' },
                padding: { xs: '10px 15px', sm: '12px 20px' },
                borderRadius: '20px',
              }}
            >
              {t('prelaunch_modal_button')}
            </Button>

            <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
              <img src={AvatarsGroup} alt='Community Avatars' style={{ width: '160px' }} />
            </Box>
          </>
        ) : (
          <>
            <img src={SuccessIcon} alt='Success' style={{ width: '200px', marginBottom: '10px' }} />
            <Typography>{t('prelaunch_modal_success')}</Typography>
            <Typography fontWeight='bold' sx={{ marginBottom: '20px', fontSize: '18px' }}>
              {t('prelaunch_modal_success_project', { projectName: project.title })}
            </Typography>
            <Typography sx={{ marginBottom: '20px' }}>{t('prelaunch_modal_success_description')}</Typography>
            <Button className='btn-primary' onClick={copyToClipboard} sx={{ borderRadius: '20px' }}>
              {copied ? t('prelaunch_modal_success_copied') : t('prelaunch_modal_success_copy')}
            </Button>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PrelaunchSubscription;
