import React from 'react';
import PropTypes from 'prop-types';
import DisplayImageOrText from './DisplayImageOrText';
import { Button } from '@mui/material';

const FileInputField = ({ value, onChange }) => {
  return (
    <div style={{ marginBottom: '10px' }}>
      {value && <DisplayImageOrText value={value} />}
      <Button variant='contained' component='label'>
        Upload File
        <input type='file' hidden onChange={(e) => onChange(e.target.files[0])} />
      </Button>
    </div>
  );
};

FileInputField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FileInputField;
