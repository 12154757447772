import { TextField, Grid, Divider } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useLocation } from 'react-router-dom';
import UserDataService from '../../services/user.service';
import LoginForm from '../auth/LoginForm';
import RegisterForm from '../auth/RegisterForm';
import LoginGoogle from '../auth/LoginGoogle';

const useStyle = makeStyles()({
  field: {
    margin: '10px !important',
    flex: '1',
    '& input::-webkit-outer-spin-button,& input::-webkit-inner-spin-button': {
      webkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]': {
      mozAppearance: 'textfield',
    },
  },
});

export function IdentificationUser(props) {
  const { isLoading, callback } = props;
  const { classes } = useStyle();
  const { t } = useTranslation();
  const [errors, setErrors] = useState({ email: false });
  const [email, setEmail] = useState('');
  const [showLoginForm, setShowLoginForm] = useState(-1); // -1 = no, 0 = login form, 1 = registration form
  const location = useLocation();

  const checkUserExists = () => {
    UserDataService.userExists({ email })
      .then(async (response) => {
        if (response.data.exists) {
          setShowLoginForm(0);
        } else if (!response.data.exists) {
          setShowLoginForm(1);
        }
      })
      .catch(() => {});
  };

  const handleKeyPress = (event, submitFormFn) => {
    if (event.key === 'Enter') {
      submitFormFn();
    }
  };

  const handleSubmit = async () => {
    if (email.length !== 0) {
      checkUserExists();
    } else {
      setErrors({ email: true });
    }
  };

  const connectUser = (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', width: '400px', margin: 'auto' }}>
      <h1 style={{ textAlign: 'center', fontSize: '1.7rem' }}>{t('connect_to_continue')}</h1>
      <LoginForm handleBack={() => setShowLoginForm(-1)} afterLogin={callback} email={email} />
    </div>
  );

  const registerUser = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <h1 style={{ textAlign: 'center', fontSize: '1.7rem' }}>{t('create_account_to_continue')}</h1>
      <RegisterForm
        handleBack={() => setShowLoginForm(-1)}
        afterCreation={callback}
        from={'from_contribution'}
        location={location.state?.prevPath}
        autoConnect
        email={email}
        role={1}
      />
    </div>
  );

  const checkUser = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {/*<h1 style={{ textAlign: 'center' }}>{t('continue_checkout_title')}</h1>*/}
      {/*<span style={{ textAlign: 'center', marginBottom: '35px' }}>{t('continue_checkout_subtitle')}</span>*/}
      <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>{t('email')}</span>
      <TextField
        hiddenLabel
        className={classes.field}
        placeholder={t('fill_email')}
        variant='outlined'
        type='email'
        value={email}
        error={errors.email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyPress={(event) => handleKeyPress(event, handleSubmit)}
      />

      <LoadingButton
        disabled={isLoading}
        style={{ margin: '20px auto' }}
        loadingPosition='end'
        endIcon={<span className='material-icons'>navigate_next</span>}
        loading={isLoading}
        className='btn-primary'
        fullWidth
        onClick={handleSubmit}
      >
        {t('continue')}
      </LoadingButton>
      <Divider sx={{ mb: 3 }}>{t('or')}</Divider>
      <LoginGoogle from={'from_registration_page'} callback={callback} />
    </div>
  );

  return (
    <Grid container style={{ justifyContent: 'center' }}>
      <Grid item width={{ xs: '100%' }} px={2} py={3} pb={5} maxWidth={'400px'}>
        {showLoginForm === -1 ? checkUser : showLoginForm === 0 ? connectUser : registerUser}
      </Grid>
    </Grid>
  );
}
