import React, { Suspense, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DialogActions, Grid, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import { Close as CloseIcon, Done as DoneIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { AboutExpert } from '../user/AboutExpert';
import { ItemsSale } from './ItemsSale';
import ProjectDataService from '../../services/project.service';
import { CartProjectModal } from './CartProjectModal';
import PackService from '../../services/pack.service';
import ConfirmationDialog from '../ConfirmationDialog';
import i18n from '../../i18n';
import { themeContext } from '../../uses/theme.uses';
import defaultPicture from '../../assets/images/login.svg';
import CartDataService from '../../services/cart.service';
import { Loading } from '../Loading';
import { SocialProject } from './SocialProject';
import { Dialog } from '../Dialog';
import SDGBanner from '../SDGBanner';

export const TabDescription = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isOwner, isCollaborator, isPrelaunchPhase, project, remainingDays } = props;
  const [donationAmount, setDonationAmount] = useState(null);
  const [pack, setPack] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [idDeletePack, setIdDeletePack] = useState(false);
  const [modal, setModal] = useState({ open: false });
  const [packs, setPacks] = useState([]);
  const { theme } = useContext(themeContext);
  const { refContribute } = ref;

  const getLabel = (field) => {
    const labels = JSON.parse(field.label);
    const language = (i18n.language ?? 'en').toLowerCase();
    return labels[language] ?? labels[Object.keys(labels)[0]];
  };

  const descriptionWrapper = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {/*{(isOwner || isCollaborator) && (*/}
      {/*  <IconButton*/}
      {/*    style={{ marginLeft: 'auto' }}*/}
      {/*    onClick={() => navigate(`/dashboard/projects/${props.project.id}/edit`)}*/}
      {/*  >*/}
      {/*    <EditIcon />*/}
      {/*  </IconButton>*/}
      {/*)}*/}

      {props.project.project_presentation_fields &&
        props.project.project_presentation_fields.length > 0 &&
        props.project.project_presentation_fields.map((field, index) => (
          <Grid key={index} container direction='row' justifyContent='center'>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  marginRight: '5px',
                }}
              >
                <span className='material-icons-outlined' style={{ color: '#E05D9C' }}>
                  {field.presentation_field.icon ?? ''}
                </span>
              </div>
              <h2 style={{ fontWeight: 'normal' }}>{getLabel(field.presentation_field)}</h2>
            </Grid>
            <Grid item xs={12}>
              <div
                className='description-content'
                dangerouslySetInnerHTML={{
                  __html: field.data_value,
                }}
              />
            </Grid>
          </Grid>
        ))}
    </div>
  );

  const handleModal = (pack) => {
    modal.open = !modal.open;
    setPack(pack);
    setModal({ ...modal });
  };

  const handleModalDelete = useCallback(
    (idDelete) => {
      setIdDeletePack(idDelete);
      setOpenDelete(!openDelete);
    },
    [openDelete, setOpenDelete, setIdDeletePack],
  );

  const retrievePacks = useCallback(() => {
    ProjectDataService.getRewards(props.project.slug).then((response) => {
      setPacks(response.data);
    });
  }, [props.project.slug]);

  const deletePack = useCallback(() => {
    PackService.delete(idDeletePack).then(() => {
      retrievePacks();
      handleModalDelete();
    });
  }, [retrievePacks, handleModalDelete, idDeletePack]);

  useEffect(() => {
    if (props.project.id) {
      retrievePacks();
    }
  }, [props.project.id, retrievePacks]);

  const addToCart = async (packId) => {
    await CartDataService.addToCart({
      packId,
      price: donationAmount,
    });
    navigate('/cart');
  };

  const remaining_pack = (pack) => (!pack.quantity ? 0 : pack.quantity - pack.purchasedContributions);

  const parseDeliveryDate = (delivery) => {
    const locale = i18n.language.toLowerCase() ? i18n.language.toLowerCase() : navigator.language;
    delivery = delivery.split('-');
    if (Array.isArray(delivery) && delivery.length === 2) {
      const date = new Date(new Date().getFullYear(), delivery[0] - 1, 1);
      const month = date.toLocaleDateString(locale, { month: 'long' });
      const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
      return `${capitalizedMonth} ${delivery[1]}`;
    }
    return 'Non définie';
  };

  const generatePack = (pack, index, canContribute = true) =>
    pack.type === 'donation' ? (
      <div key={index}>
        <Grid
          container
          direction='column'
          style={{
            padding: '1rem',
            backgroundColor: '#FAFAFA',
            border: '1px solid #0000000D',
            borderRadius: '10px',
          }}
        >
          <Grid item xs={12}>
            <h3 style={{ textAlign: 'center', marginBottom: '10px' }}>{t('make_a_donation')}</h3>
            {t('project_donation_description')}
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'center', paddingBottom: '20px', paddingTop: '20px' }}>
            <FormControl required error={false} fullWidth sx={{ margin: '10px 0 20px 0' }}>
              <InputLabel htmlFor='donation_price'>{t('donation_amount')}</InputLabel>
              <OutlinedInput
                sx={{ backgroundColor: '#fff' }}
                id='donation_price'
                name='donation_price'
                label={t('donation_amount')}
                disabled={!project.published || isPrelaunchPhase}
                value={donationAmount || 0}
                onChange={(e) => setDonationAmount(parseInt(e.target.value))}
                endAdornment={<InputAdornment position='end'>€</InputAdornment>}
                type={'number'}
                onWheel={(e) => e.target.blur()}
                inputProps={{
                  min: 1,
                }}
              />
            </FormControl>
            {canContribute && (
              <Button
                className='btn-primary'
                onClick={() => addToCart(pack.id)}
                style={{
                  textTransform: 'none',
                  opacity: !donationAmount || donationAmount === 0 ? 0.5 : 1,
                  pointerEvents: !donationAmount || donationAmount === 0 ? 'none' : 'auto',
                }}
                disabled={!donationAmount || donationAmount === 0 || !project.published || isPrelaunchPhase}
              >
                {t('contribute')}
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    ) : (
      <div key={index}>
        <Grid
          container
          direction='column'
          style={{
            padding: '1rem',
            backgroundColor: '#FAFAFA',
            border: '1px solid #0000000D',
            borderRadius: '10px',
          }}
        >
          {pack.image && (
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <div
                style={{
                  width: '100%',
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={pack.image ? pack.image : defaultPicture}
                  alt={'Illustration ' + pack.title}
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>
            </Grid>
          )}
          <Grid item xs={12}>
            <h3 style={{ margin: '.5rem 0 .3rem 0' }}>{pack.title}</h3>
          </Grid>
          <Grid
            container
            item
            columnSpacing={2}
            direction='row'
            sx={{ alignItems: 'center', fontWeight: 'bold', color: theme.palette.secondary.main }}
          >
            <Grid item>
              <div style={{ fontSize: '1.75rem' }}>{Number(pack.price)} €</div>
            </Grid>
            <Grid item sx={{ height: 'fit-content' }}>
              {!['', null, '0.00'].includes(pack.oldPrice) && (
                <del style={{ fontSize: '1.2rem', color: '#0E2C11', fontWeight: 'normal' }}>
                  {Number(pack.oldPrice)} €
                </del>
              )}
            </Grid>
          </Grid>

          <Grid
            container
            item
            columnSpacing={{ xs: 0, md: 2 }}
            rowSpacing={{ xs: 2, md: 0 }}
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '5px 0 0 0',
              fontSize: '12px',
            }}
          >
            <Grid item xs={12} md={6}>
              <div
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color: 'rgba(248, 105, 184, 1)',
                  padding: '5px 10%',
                  borderRadius: '5px',
                  background: 'rgba(248, 105, 184, 0.2)',
                }}
              >
                {pack.purchasedContributions > 1
                  ? `${pack.purchasedContributions} ${t('contributions').toLowerCase()}`
                  : `${pack.purchasedContributions} ${t('contribution').toLowerCase()}`}
              </div>
            </Grid>
            <Grid item xs={12} md={6} alignItems='center' justifyContent='center'>
              <div
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: 'rgba(248, 105, 184, 1)',
                  padding: '5px 10%',
                  borderRadius: '5px',
                  background: 'rgba(248, 105, 184, 0.2)',
                }}
              >
                {remaining_pack(pack) === 0
                  ? pack.quantity
                    ? `0 ${t('left').toLowerCase()}`
                    : 'Quantité illimitée'
                  : remaining_pack(pack) > 1
                    ? `${remaining_pack(pack)} ${t('lefts').toLowerCase()}`
                    : `${remaining_pack(pack)} ${t('left').toLowerCase()}`}
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <p style={{ whiteSpace: 'pre-line' }}>{pack.description}</p>
          </Grid>
          <Grid item xs={12} style={{ fontSize: '13px' }}>
            <div>
              <span style={{ fontWeight: 'bold' }}>Livraison estimée :</span> {parseDeliveryDate(pack.delivery)}
            </div>
          </Grid>
          {canContribute && (
            <Grid
              item
              xs={12}
              style={{
                textAlign: 'center',
                paddingBottom: '10px',
                paddingTop: '20px',
              }}
            >
              <Button
                className='btn-primary'
                onClick={() => handleModal(pack)}
                style={{ textTransform: 'none', width: '100%' }}
                disabled={
                  !project.published ||
                  isPrelaunchPhase ||
                  !canContribute ||
                  remainingDays(project) <= 0 ||
                  (remaining_pack(pack) === 0 && pack.quantity)
                }
              >
                {t('contribute')}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    );

  if (!project.id) {
    return <Loading />;
  }

  return (
    <>
      <ConfirmationDialog
        fullScreen
        open={openDelete}
        handleModal={handleModalDelete}
        title={t('confirmation')}
        text={t('pack_deletion_confirmation_question')}
        action={
          <DialogActions>
            <Button className='btn-primary' onClick={() => deletePack()} startIcon={<DoneIcon />}>
              {t('yes')}
            </Button>
            <Button onClick={() => handleModalDelete()} startIcon={<CloseIcon />}>
              {t('no')}
            </Button>
          </DialogActions>
        }
      />

      <Suspense fallback={null}>
        <Dialog
          open={modal.open}
          handleModal={handleModal}
          handleModalArgs={[]}
          content={
            <CartProjectModal
              generatePack={generatePack}
              parseDeliveryDate={parseDeliveryDate}
              openDialog={handleModal}
              pack={pack}
              projectId={props.projectId}
            />
          }
        />
      </Suspense>

      <Grid
        container
        spacing={{ xs: 2, sm: 10 }}
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent='center'
        alignItems={'flex-start'}
      >
        <Grid container item xs={12} md={7} lg={8} order={{ xs: 2, sm: 2, md: 1 }} spacing={2}>
          {(!isPrelaunchPhase || isOwner || isCollaborator) && (
            <Grid item xs={12}>
              <div className='projectDescription'>{descriptionWrapper}</div>
            </Grid>
          )}

          <SocialProject project={props.project} />

          <Grid container item xs={12} justifyContent='center'>
            <AboutExpert user={props.project.user} />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={12} md={5} lg={4} order={{ xs: 1, sm: 1, md: 2 }}>
          <ItemsSale
            remainingDays={remainingDays}
            project={project}
            generatePack={generatePack}
            ref={refContribute}
            handleModalDelete={handleModalDelete}
            isOwner={isOwner}
            isCollaborator={isCollaborator}
            packs={packs}
            openDialog={handleModal}
            projectId={props.project.id}
          />
          <SDGBanner sdgs={project.sdgs} style={{ marginTop: '20px' }} />
        </Grid>
      </Grid>
    </>
  );
});
TabDescription.displayName = 'TabDescription';

export default TabDescription;
