import React from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import Project from '../../components/project/Project';
import Header from '../../components/Header';

export default function ProjectsIndex(props) {
  const location = useLocation();
  const { items, active } = props;
  const params = useParams();

  return (
    <main>
      <Header items={items(params)} active={active} />
      <Project items={items(params)} active={active} slug={params.slug} creation={!!location.state?.success} />
    </main>
  );
}
