import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import i18n from '../../../i18n';
import React, { useContext, useEffect, useState } from 'react';
import { themeContext } from '../../../uses/theme.uses';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import HelpIcon from '@mui/icons-material/Help';
import DeleteIcon from '@mui/icons-material/Delete';
import { ImageUpload } from '../../ImageUpload';

export default function CreatePackForm(props) {
  const { pack, setPack, callback, projectId } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const deliveryModes = [
    {
      value: 'shipping',
      label: t('reward_delivery_shipping_label'),
      help: t('reward_delivery_shipping_help'),
    },
    { value: 'in_person', label: t('reward_delivery_in_person_label'), help: t('reward_delivery_in_person_help') },
    { value: 'immaterial', label: t('reward_delivery_immaterial_label'), help: t('reward_delivery_immaterial_help') },
    { value: 'none', label: t('reward_delivery_none_label'), help: t('reward_delivery_none_help') },
  ];

  // Initialiser pack.options s'il n'existe pas
  useEffect(() => {
    if (!pack.options) {
      setPack((prev) => ({
        ...prev,
        options: [{ name: '', values: [] }],
      }));
    }
  }, [pack.options, setPack]);

  const validateShippingCost = (value) => {
    if (pack.deliveryMode && pack.deliveryMode === 'shipping') {
      if (!value || value.length <= 0) {
        return [t('mandatory_field')];
      }
      if (Number(value) < 0) {
        return [t('field_value_must_be_greater_than') + ' 0'];
      }
    }
    return [];
  };

  const validatePickupInstructions = (value) => {
    if (pack.deliveryMode && pack.deliveryMode === 'in_person' && (!value || value.length <= 0)) {
      return [t('mandatory_field')];
    }
    return [];
  };

  const [errors, setErrors] = useState({
    title: {
      error: [],
      getErrors: (value) => {
        if (!value || value.length <= 0) {
          return [t('mandatory_field')];
        }
        if (value.length > 80) {
          return [`${t('field_must_not_exceed')} 80 ${t('characters')}`];
        }
        return [];
      },
    },
    description: {
      error: [],
      getErrors: (value) => {
        if (!value || value.length <= 0) {
          return [t('mandatory_field')];
        }
        if (value.length > 500) {
          return [`${t('field_must_not_exceed')} 500 ${t('characters')}`];
        }
        return [];
      },
    },
    quantity: {
      error: [],
      getErrors: (value) => {
        if (!['', null, undefined].includes(value) && (!Number.isInteger(Number(value)) || Number(value) <= 0)) {
          return [t('field_must_be_empty_or_positive_decimal_number')];
        }
        return [];
      },
    },
    price: {
      error: [],
      getErrors: (value) => {
        if (!['', null, undefined].includes(value) && Number(value) <= 0) {
          return [t('field_must_be_empty_or_positive_decimal_number')];
        }
        return [];
      },
    },
    oldPrice: {
      error: [],
      getErrors: (value) => {
        if (!['', null, undefined].includes(value) && Number(value) < 0) {
          return [t('field_must_be_empty_or_positive_decimal_number')];
        }
        return [];
      },
    },
    deliveryMode: {
      error: [],
      getErrors: (value) => {
        if (!value || value.length <= 0) {
          return [t('mandatory_field')];
        }
        if (!deliveryModes.map((e) => e.value).includes(value)) {
          return [t('invalid_field_value')];
        }
        return [];
      },
    },
    shippingCost: {
      error: [],
      getErrors: validateShippingCost,
    },
    pickupInstructions: {
      error: [],
      getErrors: validatePickupInstructions,
    },
    delivery: [
      {
        error: [],
        getErrors: (value) => {
          if (!value || value.length <= 0) {
            return [t('mandatory_field')];
          }
          if (value < 1 || value > 12) {
            return [`${t('field_value_must_be_between')} 1 ${t('and')} 12`];
          }
          return [];
        },
      },
      {
        error: [],
        getErrors: (value) => {
          if (!value || value.length <= 0) {
            return [t('mandatory_field')];
          }
          if (value < Number(new Date().getFullYear())) {
            return [`${t('field_value_must_be_greater_than')} ${new Date().getFullYear()}`];
          }
          return [];
        },
      },
    ],
    options: {
      error: [],
      getErrors: (value) => {
        if (value.length <= 0) {
          return [t('at_least_one_option')];
        }
        return [];
      },
    },
  });

  const { theme } = useContext(themeContext);
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const sm = useMediaQuery(theme.breakpoints.up('sm'));

  // Mettez à jour les erreurs de validation pour shippingCost et pickupInstructions chaque fois que pack.deliveryMode change
  useEffect(() => {
    const updateErrors = () => {
      setErrors((prevErrors) => ({
        ...prevErrors,
        shippingCost: {
          ...prevErrors.shippingCost,
          getErrors: (v) => validateShippingCost(v),
        },
        pickupInstructions: {
          ...prevErrors.pickupInstructions,
          getErrors: (v) => validatePickupInstructions(v),
        },
      }));
    };

    // Appelez la fonction de mise à jour des erreurs chaque fois que pack.deliveryMode change
    updateErrors();
    // eslint-disable-next-line
  }, [pack.deliveryMode]);

  const getMonthName = (monthNumber) => {
    const currentYear = new Date().getFullYear();
    const date = new Date(currentYear, monthNumber - 1, 1);
    const monthName = date.toLocaleDateString(i18n.language.toLowerCase() === 'fr' ? 'fr' : 'en', { month: 'long' });

    return monthName.charAt(0).toUpperCase() + monthName.slice(1);
  };

  const checkForm = () => {
    let error = false;
    const tempErrors = Object.keys(errors).reduce((acc, key) => {
      let _errors;
      // If multiple errors
      if (Array.isArray(errors[key])) {
        acc[key] = errors[key].map((element, elementIndex) => {
          _errors = 'getErrors' in element ? element.getErrors(pack[key][elementIndex]) : [];
          return {
            ...element,
            error: _errors,
          };
        });
      } else {
        _errors = 'getErrors' in errors[key] ? errors[key].getErrors(pack[key]) : [];
        acc[key] = {
          ...errors[key],
          error: _errors,
        };
      }
      if (Array.isArray(_errors) && _errors.length > 0) {
        error = true;
      }
      return acc;
    }, {});
    setErrors(() => tempErrors);
    return !error;
  };

  if (!pack.options) {
    return null;
  }

  return (
    <Grid container sx={{ p: 2 }} direction='column' justifyContent='center' alignItems='center' spacing={5}>
      <Grid item container spacing={2}>
        <Grid container item xs={12} direction={'row'} alignItems='center'>
          <Typography content='span' sx={{ fontWeight: 'bold' }}>
            {t('description_counterpart')}
          </Typography>
          <Tooltip title={t('title_description')}>
            <IconButton aria-label='help' size='small' style={{ verticalAlign: 'middle', marginLeft: '.2rem' }}>
              <HelpIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
        </Grid>

        {/* Title */}
        <Grid item xs={12}>
          <TextField
            InputLabelProps={{ shrink: true }}
            inputProps={{ sx: { color: '#000' } }}
            label={t('title')}
            placeholder={t('add_title')}
            fullWidth
            value={pack.title}
            error={errors.title.error.length > 0}
            onChange={(e) => setPack((curr) => ({ ...curr, title: e.target.value }))}
            InputProps={{ inputProps: { maxLength: 80 } }}
            helperText={
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box component='span' sx={{ color: 'red' }}>
                  {errors.description.error.join(' ')}
                </Box>
                <Box component='span' sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {`${pack.title.length ?? 0}/80`}
                </Box>
              </Box>
            }
          />
        </Grid>

        {/* Description */}
        <Grid item xs={12}>
          <TextField
            InputLabelProps={{ shrink: true }}
            inputProps={{ sx: { color: '#000' } }}
            label={t('description')}
            placeholder={t('add_description')}
            fullWidth
            value={pack.description}
            error={errors.description.error.length > 0}
            onChange={(e) => setPack((curr) => ({ ...curr, description: e.target.value }))}
            multiline
            rows={4}
            InputProps={{ inputProps: { maxLength: 500 } }}
            helperText={
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box component='span' sx={{ color: 'red' }}>
                  {errors.description.error.join(' ')}
                </Box>
                <Box component='span' sx={{ justifyContent: 'flex-end' }}>
                  {`${pack.description.length ?? 0}/500`}
                </Box>
              </Box>
            }
          />
        </Grid>
      </Grid>

      {/* Picture */}
      <Grid item container spacing={2}>
        <Grid item xs={12} direction={'row'} alignItems='center'>
          <Typography content='span' sx={{ fontWeight: 'bold' }}>
            {t('counterpart_picture')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ImageUpload
            image={pack.image}
            onChange={(event) => setPack((curr) => ({ ...curr, image: event.target.files[0] }))}
            onDelete={() => setPack((curr) => ({ ...curr, image: null }))}
            suggestedSize='426x240 pixels'
            acceptedTypes={['image/jpeg', 'image/png', 'image/gif']}
          />
        </Grid>
      </Grid>

      {/* Quantity */}
      <Grid item container spacing={2}>
        <Grid item xs={12} direction={'row'} alignItems='center'>
          <Typography content='span' sx={{ fontWeight: 'bold' }}>
            {t('available_quantity')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            type={'number'}
            InputLabelProps={{ shrink: true }}
            inputProps={{ sx: { color: '#000' }, min: 0 }}
            fullWidth
            label={t('quantity')}
            placeholder={t('unlimited')}
            value={pack.quantity}
            error={errors.quantity.error.length > 0}
            helperText={
              errors.quantity.error.length > 0 ? errors.quantity.error.join('<br>') : t('let_empty_for_unlimited')
            }
            onChange={(e) => setPack((curr) => ({ ...curr, quantity: e.target.value }))}
          />
        </Grid>
      </Grid>

      {/* Price */}
      <Grid item container spacing={2}>
        <Grid container item xs={12} direction={'row'} alignItems='center'>
          <Typography content='span' sx={{ fontWeight: 'bold' }}>
            {t('contribution_price')}
          </Typography>
          <Tooltip title={t('crossed_out_price_description')}>
            <IconButton aria-label='help' size='small' style={{ verticalAlign: 'middle', marginLeft: '.2rem' }}>
              <HelpIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: 'flex', flexDirection: sm || md ? 'row' : 'column' }}>
            <TextField
              type={'number'}
              InputLabelProps={{ shrink: true }}
              inputProps={{ sx: { color: '#000' } }}
              label={t('contribution_price')}
              placeholder='0'
              fullWidth
              InputProps={{ endAdornment: <InputAdornment position='end'>€</InputAdornment> }}
              value={pack.price}
              error={errors.price.error.length > 0}
              helperText={errors.price.error.join('<br>')}
              onChange={(e) => setPack((curr) => ({ ...curr, price: e.target.value }))}
            />
            <div
              style={{
                width: sm || md ? '1.5rem' : '100%',
                height: sm || md ? '100%' : '1.5rem',
              }}
            />
            <TextField
              type={'number'}
              InputLabelProps={{ shrink: true }}
              inputProps={{ sx: { color: '#000' } }}
              label={`${t('crossed_out_price')} (${t('optional')})`}
              placeholder='0'
              fullWidth
              InputProps={{ endAdornment: <InputAdornment position='end'>€</InputAdornment> }}
              value={pack.oldPrice}
              error={errors.oldPrice.error.length > 0}
              helperText={errors.oldPrice.error.join('<br>')}
              onChange={(e) => setPack((curr) => ({ ...curr, oldPrice: e.target.value }))}
            />
          </div>
        </Grid>
      </Grid>

      {/* Delivery mode */}
      <Grid item container spacing={2}>
        <Grid container item xs={12} direction={'row'} alignItems='center'>
          <Typography content='span' sx={{ fontWeight: 'bold' }}>
            {t('delivery_mode')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl component='fieldset' fullWidth error={errors.deliveryMode.error.length}>
            <FormGroup>
              {deliveryModes.map((mode) => (
                <Grid
                  key={mode.label}
                  xs={12}
                  style={{
                    border: '1px solid #ccc',
                    padding: '8px',
                    borderRadius: '4px',
                    marginBottom: '8px',
                  }}
                >
                  <FormControlLabel
                    sx={{
                      marginLeft: '-5px',
                    }}
                    slotProps={{
                      typography: { sx: { width: '100%' } },
                    }}
                    control={
                      <Checkbox
                        checked={pack.deliveryMode === mode.value}
                        onChange={() => {
                          setPack((curr) => ({ ...curr, deliveryMode: mode.value }));
                        }}
                        name={mode.label}
                      />
                    }
                    style={{ alignItems: 'flex-start', width: '100%' }}
                    label={
                      <Grid container xs={12} direction={'column'}>
                        <Typography variant='body1' style={{ fontWeight: '600' }}>
                          {mode.label}
                        </Typography>
                        {mode.help && <Typography variant='caption'>{mode.help}</Typography>}
                        {pack.deliveryMode === 'shipping' && mode.value === 'shipping' && (
                          <Grid xs={12} mt={2}>
                            <TextField
                              InputProps={{
                                endAdornment: <InputAdornment position='start'>€</InputAdornment>,
                              }}
                              label='Montant des frais de livraison'
                              error={errors.shippingCost.error.length > 0}
                              helperText={errors.shippingCost.error.join('<br>')}
                              type='number'
                              value={pack.shippingCost}
                              onChange={(e) => setPack((curr) => ({ ...curr, shippingCost: e.target.value }))}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant='outlined'
                            />
                          </Grid>
                        )}
                        {pack.deliveryMode === 'in_person' && mode.value === 'in_person' && (
                          <Grid xs={12} mt={2}>
                            <TextField
                              label='Indiquer où et quand récupérer la contrepartie'
                              error={errors.pickupInstructions.error.length > 0}
                              helperText={errors.pickupInstructions.error.join('<br>')}
                              minRows={3}
                              fullWidth
                              multiline
                              value={pack.pickupInstructions}
                              onChange={(e) => setPack((curr) => ({ ...curr, pickupInstructions: e.target.value }))}
                              variant='outlined'
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    }
                  />
                </Grid>
              ))}
            </FormGroup>
            {errors.deliveryMode.error.length > 0 && (
              <FormHelperText>{errors.deliveryMode.error.join('<br>')}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>

      {/* Delivery estimated */}
      <Grid item container spacing={2}>
        <Grid container item xs={12} direction={'row'} alignItems='center'>
          <Typography content='span' sx={{ fontWeight: 'bold' }}>
            {t('estimated_delivery')}
          </Typography>
          <Tooltip title={t('delivery_description')}>
            <IconButton aria-label='help' size='small' style={{ verticalAlign: 'middle', marginLeft: '.2rem' }}>
              <HelpIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: 'flex', flexDirection: sm || md ? 'row' : 'column' }}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={i18n.language.toLowerCase() === 'fr' ? 'fr' : 'en-gb'}
            >
              <TextField
                InputLabelProps={{ shrink: true }}
                inputProps={{ sx: { color: '#000' } }}
                label={t('month')}
                fullWidth
                select
                value={pack.delivery[0] ?? 0}
                error={errors.delivery[0].error.length > 0}
                helperText={errors.delivery[0].error.join('<br>')}
                onChange={(e) => setPack((curr) => ({ ...curr, delivery: [e.target.value, curr.delivery[1]] }))}
              >
                {Array.from({ length: 12 }, (_, index) => index + 1).map((i) => (
                  <MenuItem key={i} value={i}>
                    {getMonthName(i)}
                  </MenuItem>
                ))}
              </TextField>
            </LocalizationProvider>
            <div
              style={{
                width: sm || md ? '1.5rem' : '100%',
                height: sm || md ? '100%' : '1.5rem',
              }}
            />
            <TextField
              type={'number'}
              InputLabelProps={{ shrink: true }}
              inputProps={{ sx: { color: '#000' }, min: new Date().getFullYear() }}
              label={t('year')}
              fullWidth
              value={pack.delivery[1]}
              error={errors.delivery[1].error.length > 0}
              helperText={errors.delivery[1].error.join('<br>')}
              onChange={(e) => setPack((curr) => ({ ...curr, delivery: [curr.delivery[0], e.target.value] }))}
            />
          </div>
        </Grid>
      </Grid>

      {/* Options */}
      <Grid item container spacing={2}>
        <Grid container item xs={12} direction={'row'} alignItems='center'>
          <Typography content='span' sx={{ fontWeight: 'bold' }}>
            {t('options')}
          </Typography>
          <Tooltip title={t('options_description')}>
            <IconButton aria-label='help' size='small' style={{ verticalAlign: 'middle', marginLeft: '.2rem' }}>
              <HelpIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
          <Chip label={t('new')} size='small' style={{ backgroundColor: '#FFDB44' }} />
        </Grid>

        {/* Container for each option */}
        {pack.options?.map((option, optionIndex) => (
          <Grid item xs={12} key={optionIndex}>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={12} sm={5}>
                <TextField
                  label={'Option ' + (optionIndex + 1)}
                  value={option.name}
                  error={errors.options.error.length > 0}
                  placeholder={t('option_placeholder')}
                  onChange={(e) => {
                    const newOptions = [...pack.options];
                    newOptions[optionIndex].name = e.target.value;
                    setPack((prev) => ({ ...prev, options: newOptions }));
                  }}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant='outlined'
                  InputProps={{ inputProps: { maxLength: 30 } }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple
                  freeSolo
                  options={[]}
                  value={option.values?.map((val) => val.value)}
                  onChange={(event, newValue) => {
                    const newOptions = [...pack.options];
                    newOptions[optionIndex].values = newValue.map((val) => ({
                      value: val,
                      quantity: option.values.find((v) => v.value === val)?.quantity || null,
                    }));
                    setPack((prev) => ({ ...prev, options: newOptions }));
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((optionValue, index) => (
                      <Chip variant='outlined' label={optionValue} key={index} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label={t('option_values')}
                      placeholder={t('add_values')}
                      onBlur={(e) => {
                        if (e.target.value) {
                          const newOptions = [...pack.options];
                          newOptions[optionIndex].values.push({
                            value: e.target.value,
                            quantity: null,
                          });
                          setPack((prev) => ({ ...prev, options: newOptions }));
                          params.inputProps.onChange({ target: { value: '' } });
                        }
                      }}
                    />
                  )}
                />
              </Grid>

              {/* Delete option button */}
              <Grid item xs={'auto'} style={{ textAlign: 'right' }}>
                <IconButton
                  aria-label='remove option'
                  onClick={() => {
                    setPack((prev) => ({
                      ...prev,
                      options: prev.options.filter((_, idx) => idx !== optionIndex),
                    }));
                  }}
                >
                  <DeleteIcon fontSize='small' style={{ color: '#d32f2f' }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ))}

        {/* Add Option Button */}
        <Grid item xs={12}>
          <Button
            onClick={() => {
              setPack((prev) => ({
                ...prev,
                options: [...prev.options, { name: '', values: [] }],
              }));
            }}
            className='btn-grey btn-grey--small'
            sx={{
              backgroundColor: '#1976d2',
              color: 'white',
              borderRadius: '5px',
              padding: '0.5rem 1rem',
              '&:hover': {
                backgroundColor: '#1565c0',
              },
            }}
          >
            {t('add_option')}
          </Button>
        </Grid>
      </Grid>

      {/* Options values availability */}
      {pack.options?.some((opt) => opt.values?.length > 0) && (
        <Grid item container spacing={2}>
          <Grid container item xs={12} direction={'row'} alignItems='center'>
            <Typography content='span' sx={{ fontWeight: '600' }}>
              {t('option_availability')} ({t('optional')})
            </Typography>
          </Grid>

          {pack.options.map((option, optionIndex) => (
            <React.Fragment key={optionIndex}>
              {option.values.map((val, valIndex) => (
                <Grid container item key={valIndex}>
                  <Grid container item xs={12} spacing={2} alignItems='center'>
                    <Grid item xs={9}>
                      <Typography variant='body2'>{val.value}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label={t('quantity')}
                        type='number'
                        InputLabelProps={{ shrink: true }}
                        placeholder={t('unlimited')}
                        value={val.quantity}
                        onChange={(e) => {
                          const newQuantity = parseInt(e.target.value, 10);
                          const newOptions = [...pack.options];
                          newOptions[optionIndex].values[valIndex].quantity = newQuantity;
                          setPack((prev) => ({ ...prev, options: newOptions }));
                        }}
                        InputProps={{ inputProps: { min: 1, max: pack.quantity ?? 1000 } }}
                        sx={{
                          '& .MuiInputBase-input': {
                            padding: '10px',
                          },
                        }}
                        variant='outlined'
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </React.Fragment>
          ))}
        </Grid>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '2rem',
        }}
      >
        <Button
          className='btn-grey'
          style={{ border: 'solid red !important' }}
          onClick={() => navigate(`/project/${projectId}`)}
        >
          {t('back')}
        </Button>
        <div
          style={{
            width: sm || md ? '1.5rem' : '100%',
            height: sm || md ? '100%' : '1.5rem',
          }}
        />
        <Button
          className='btn-primary'
          onClick={() => {
            if (checkForm()) {
              callback(pack);
            }
          }}
        >
          {t('save')}
        </Button>
      </div>
    </Grid>
    // </Container>
  );
}
