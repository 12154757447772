import http from '../http-common';

class SDGDataService {
  getAll() {
    return http.get(`/sdg`);
  }

  get(id) {
    return http.get(`/sdg/${id}`);
  }

  create(data) {
    return http.post(`/sdg`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  update(id, data) {
    return http.put(`/sdg/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  delete(id) {
    return http.delete(`/sdg/${id}`);
  }
}

export default new SDGDataService();
