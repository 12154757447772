import React, { Suspense, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Tab } from '@mui/material';
import Confetti from 'react-dom-confetti';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { TabDescription } from './TabDescription';
import ProjectDataService from '../../services/project.service';
import CollaboratorDataService from '../../services/collaborator.service';
import '../../css/_project.scss';
import { Loading } from '../Loading';
import { UserContext } from '../../uses/users.context';
import Footer from '../Footer';
import { Heading } from './Heading';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactPixel from 'react-facebook-pixel';
import { AdminHeader } from './AdminHeader';
import Badge from '@mui/material/Badge';
import { themeContext } from '../../uses/theme.uses';
import SEO from '../SEO';
import PartnershipsProjectBanner from '../PartnershipsProjectBanner';
import PrelaunchSubscription from '../../features/project/components/PrelaunchSubscription';

// Lazy Loading component
// const Parcours = React.lazy(() => import('./Parcours').then((module) => ({ default: module.default })));
const Actualites = React.lazy(() => import('./Actualites').then((module) => ({ default: module.default })));
const Contributions = React.lazy(() => import('./Contributions').then((module) => ({ default: module.Contributions })));
const FAQ = React.lazy(() => import('./FAQ').then((module) => ({ default: module.default })));

function Project(props) {
  const { t } = useTranslation();
  const { theme } = useContext(themeContext);
  const { user } = useContext(UserContext);
  const [project, setProject] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [isCollaborator, setIsCollaborator] = useState(false);
  const [confetti, setConfetti] = useState(false);
  const [tabValue, setTabValue] = useState('0');
  const { slug, creation } = props;
  const refContribute = useRef(null);
  const refSubscribe = useRef(null);
  const navigate = useNavigate();

  // Initialisez le Pixel Facebook for project
  const initProjectPixel = (pixelId) => {
    if (!pixelId) {
      return;
    }

    // Initialisez le Facebook Pixel avec le nouvel identifiant
    ReactPixel.init(pixelId, { autoConfig: true, debug: process.env.REACT_APP_ENVIRONMENT === 'development' });

    // Signalez la page vue une fois le Pixel initialisé
    ReactPixel.pageView();

    // Envoyez des événements de temps passé sur la page toutes les 10 secondes
    setInterval(() => {
      ReactPixel.trackCustom('TimeSpentOnPage', { time: 10 });
    }, 10000); // Envoie toutes les 10 secondes
  };

  const config = {
    angle: '90',
    spread: '141',
    startVelocity: '50',
    elementCount: '200',
    dragFriction: '0.09',
    duration: '6970',
    stagger: '15',
    width: '10px',
    height: '15px',
    perspective: '1000px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  };

  const remainingDays = (project) => {
    let endDateWithoutTime = new Date(project.endDate);
    endDateWithoutTime.setHours(0, 0, 0, 0);
    let now = new Date();
    now.setHours(0, 0, 0, 0);
    const time_diff = endDateWithoutTime.getTime() - now.getTime();
    return Math.floor(time_diff / (1000 * 3600 * 24)) + 1; // On ajoute +1 car le jour de fin du projet compte comme un jour restant
  };

  const handleChange = (event, value) => {
    setTabValue(value);
  };

  const switchToContribute = () => {
    setTabValue('0');
    setTimeout(() => {
      refContribute.current.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const retrieveProject = useCallback(() => {
    ProjectDataService.get(slug)
      .then((responseProject) => {
        responseProject.data = ProjectDataService.decrypt([responseProject.data])[0];
        if (slug.match(/^[0-9]*$/)) {
          // Redirect to the project URL with slug
          navigate(`/project/${responseProject.data.slug}`);
          return;
        }
        initProjectPixel(responseProject.data.pixel_id);
        if (user) {
          CollaboratorDataService.getByProject(responseProject.data.id).then((responseCollaborators) => {
            responseCollaborators.data = CollaboratorDataService.decrypt(responseCollaborators.data);
            setProject({ ...responseProject.data, collaborators: responseCollaborators.data });
            if (user.id === responseProject.data.user.id) {
              setIsOwner(true);
            } else {
              setIsOwner(user.role.rank === 2);
            }
            setIsCollaborator(
              responseCollaborators.data.findIndex((collaborator) => collaborator.email === user.email) !== -1,
            );
          });
        } else {
          setProject({ ...responseProject.data });
          setIsOwner(false);
          setIsCollaborator(false);
        }
      })
      .catch((e) => {
        // Not found
        if (e?.response?.status === 404) {
          setTimeout(() => toast.error(t('toast_project_not_found')), 500);
          navigate('/search');
        }
      });
  }, [slug, navigate, t, user]);

  useEffect(() => {
    retrieveProject();
  }, [retrieveProject]);

  useEffect(() => {
    if (creation) {
      setConfetti(true);
    }
  }, [creation]);

  const handleProject = (project, validate) => {
    ProjectDataService.update(project.id, { ...project, published: validate }).then(() => {
      setProject({ ...project, published: validate });
    });
  };

  const handleModal = (type, key) => {
    open[type][key] = !open[type][key];
  };

  if (!project) return;

  const isPrelaunchPhase = project.prelaunch && new Date(project.startDate) > new Date();

  if (!isOwner && !isCollaborator && isPrelaunchPhase) {
    return <PrelaunchSubscription project={project} />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <SEO
        title={project.title + ' - My Moojo'}
        description={project.description}
        name={project?.user?.firstName}
        image={project.image}
        type='website'
      />
      {project && (
        <div style={{ flex: 1 }}>
          {creation && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '95vw',
                height: '95vh',
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <Confetti active={confetti} config={config} />
              </div>
            </div>
          )}
          <AdminHeader isOwner={isOwner} isCollaborator={isCollaborator} project={project} setProject={setProject} />
          <PartnershipsProjectBanner partnerships={project.partnerships} />
          <Heading
            remainingDays={remainingDays}
            isOwner={isOwner}
            isCollaborator={isCollaborator}
            switchToContribute={switchToContribute}
            openDialog={handleModal}
            collaborators={project.collaborators}
            state={project.published}
            handleProject={handleProject}
            project={project}
          />
          <TabContext value={tabValue}>
            <TabList
              onChange={handleChange}
              variant='scrollable'
              scrollButtons='auto'
              style={{
                backgroundColor: '#fafafa',
                borderBottom: '1px solid #e5e5e5',
              }}
              sx={{
                '& .MuiTabs-flexContainer': {
                  padding: '10px',
                  justifyContent: { xs: 'flex-start', sm: 'center' },
                },
              }}
            >
              <Tab style={{ textTransform: 'capitalize' }} label={t('presentation')} value='0' />
              {/*<Tab style={{ textTransform: 'capitalize' }} label={t('roadmap')} value='1' />*/}
              <Tab
                style={{ textTransform: 'capitalize' }}
                label={t('news')}
                value='2'
                icon={
                  <Badge
                    badgeContent={project.totalNews || '0'}
                    color='secondary'
                    slotProps={{ badge: { style: { backgroundColor: theme.palette.secondary.main } } }}
                    style={{
                      verticalAlign: 'middle',
                      marginLeft: '15px',
                    }}
                  ></Badge>
                }
                iconPosition='end'
              />
              {project.faqs && JSON.parse(project.faqs).length > 0 && (
                <Tab
                  style={{ textTransform: 'capitalize' }}
                  label={t('project_step_faq')}
                  value='3'
                  icon={
                    <Badge
                      badgeContent={JSON.parse(project.faqs).length}
                      color='secondary'
                      slotProps={{ badge: { style: { backgroundColor: theme.palette.secondary.main } } }}
                      style={{
                        verticalAlign: 'middle',
                        marginLeft: '15px',
                      }}
                    ></Badge>
                  }
                  iconPosition='end'
                />
              )}
              <Tab
                style={{ textTransform: 'capitalize' }}
                value='4'
                label={t('contributors')}
                icon={
                  <Badge
                    badgeContent={project.totalContributors || '0'}
                    color='secondary'
                    slotProps={{ badge: { style: { backgroundColor: theme.palette.secondary.main } } }}
                    style={{
                      verticalAlign: 'middle',
                      marginLeft: '15px',
                    }}
                  ></Badge>
                }
                iconPosition='end'
              />
            </TabList>

            <Box className='container' style={{ paddingTop: '24px', paddingBottom: '24px' }}>
              <TabPanel value='0'>
                <TabDescription
                  remainingDays={remainingDays}
                  ref={{
                    refContribute,
                    refSubscribe,
                  }}
                  project={project}
                  items={project.items}
                  isOwner={isOwner}
                  isCollaborator={isCollaborator}
                  isPrelaunchPhase={isPrelaunchPhase}
                />
              </TabPanel>
              {/*<TabPanel value='1'>*/}
              {/*  <Grid container direction='row' justifyContent='center'>*/}
              {/*    <Grid item xs={12} sm={12} md={12} lg={7}>*/}
              {/*      <Suspense fallback={<Loading />}>*/}
              {/*        <Parcours*/}
              {/*          collect={project.collect}*/}
              {/*          handleModal={handleModal}*/}
              {/*          items={project.items}*/}
              {/*          isOwner={isOwner}*/}
              {/*          isCollaborator={isCollaborator}*/}
              {/*          projectId={project.id}*/}
              {/*        />*/}
              {/*      </Suspense>*/}
              {/*    </Grid>*/}
              {/*    {(isOwner || isCollaborator) && (*/}
              {/*      <Grid item xs={12} sm={12} md={12} lg={3}>*/}
              {/*        <Suspense fallback={<Loading />}>*/}
              {/*          <Comments*/}
              {/*            comments={project.comments}*/}
              {/*            isOwner={isOwner}*/}
              {/*            isCollaborator={isCollaborator}*/}
              {/*            projectId={project.id}*/}
              {/*          />*/}
              {/*        </Suspense>*/}
              {/*      </Grid>*/}
              {/*    )}*/}
              {/*  </Grid>*/}
              {/*</TabPanel>*/}
              <TabPanel value='2'>
                <Suspense fallback={<Loading />}>
                  <Actualites
                    projectId={project.id}
                    projectSlug={project.slug}
                    isOwner={isOwner}
                    user={user}
                    isCollaborator={isCollaborator}
                  />
                </Suspense>
              </TabPanel>
              <TabPanel value='3'>
                <Suspense fallback={<Loading />}>
                  <FAQ faqs={project.faqs} />
                </Suspense>
              </TabPanel>
              <TabPanel value='4'>
                <Suspense fallback={<Loading />}>
                  <Contributions
                    projectSlug={project.slug}
                    isOwner={isOwner}
                    user={user}
                    isCollaborator={isCollaborator}
                  />
                </Suspense>
              </TabPanel>
            </Box>
          </TabContext>
        </div>
      )}
      <Footer />
    </Box>
  );
}

export default Project;
