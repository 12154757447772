import HeadingNavBar from '../Dashboard/HeadingNavBar';
import React, { useEffect } from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ProjectDataService from '../../services/project.service';
import Paper from '@mui/material/Paper';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Loading } from '../Loading';
import { toast } from 'react-toastify';
import { exportToExcel } from '../../utils/export';
import LoadingButton from '@mui/lab/LoadingButton';
import IosShareIcon from '@mui/icons-material/IosShare';

function ProjectSubscribersDetail(props) {
  const [projectSubscribers, setProjectSubscribers] = React.useState(null);
  const [loadingExport, setLoadingExport] = React.useState(false);
  const { t } = useTranslation();

  function retrieveSubscribers(projectId) {
    ProjectDataService.getProjectSubscribers(projectId).then((res) => {
      setProjectSubscribers(res.data.value);
    });
  }

  function handleExport() {
    if (projectSubscribers.length === 0) {
      toast.error(t('error_export_subscribers_empty'));
      return;
    }

    setLoadingExport(true);
    ProjectDataService.exportProjectSubscribers(props.projectId)
      .then((res) => {
        const blob = res.data;
        const reader = new FileReader();

        reader.onload = () => {
          const text = reader.result;
          const jsonData = JSON.parse(text);

          exportToExcel(jsonData, 'export_subscribers_project_' + props.projectId);
        };

        reader.readAsText(blob);

        setLoadingExport(false);
      })
      .catch(() => {
        toast.error(t('error_export_subscribers'));
        setLoadingExport(false);
      });
  }

  useEffect(() => {
    retrieveSubscribers(props.projectId);
  }, [props.projectId]);

  if (!projectSubscribers) return <Loading />;

  return (
    <>
      <HeadingNavBar backPath={`/dashboard/projects/${props.projectId}`} />
      <Grid container direction='row' justifyContent='center' sx={{ p: 3 }}>
        <Grid item xs={12} sx={{ textAlign: 'left' }}>
          <Grid
            container
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Grid item>
              <h1>{t('project_subscribers_title')}</h1>
            </Grid>
            <Grid item>
              <LoadingButton
                endIcon={<IosShareIcon />}
                className={'btn-grey'}
                loading={loadingExport}
                loadingPosition='end'
                onClick={() => handleExport()}
              >
                {t('export_button_text')}
              </LoadingButton>
            </Grid>
          </Grid>
          <span>{t('project_subscribers_subtitle')}</span>
        </Grid>

        <Grid container item spacing={5} marginTop={2}>
          {(projectSubscribers.length > 0 && (
            <Grid item>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell>Abonné le</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectSubscribers.map((row) => (
                      <TableRow key={row.email}>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{Moment(row.createdAt).locale('fr').format('D/M/yyyy')}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )) || (
            <Grid item>
              <p>Aucun abonné pour le moment.</p>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default ProjectSubscribersDetail;
