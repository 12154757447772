import i18n from '../../i18n';
import { GoogleLogin } from '@react-oauth/google';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import UserDataService from '../../services/user.service';
import { UserContext } from '../../uses/users.context';
import { useNavigate } from 'react-router-dom';
import { decrypt } from '../../utils/crypto';
import SegmentService from '../../services/segment.service';

function LoginGoogle(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [googleLoginWidth, setGoogleLoginWidth] = useState(400);
  const { setUser } = useContext(UserContext);
  const { location, isRegister, callback } = props;

  const googleLoginParentRef = useRef();
  useEffect(() => {
    // Allow to get the width of the parent element for the GoogleLogin component
    setGoogleLoginWidth(googleLoginParentRef.current.clientWidth);
  }, []);

  const responseMessage = (credentialResponse) => {
    UserDataService.loginGoogle(credentialResponse?.credential).then(async (response) => {
      if ('type' in response.data && response.data.type === 'success') {
        await setUser(response.data.value);
        await i18n.changeLanguage(response.data.value.language);

        SegmentService.identify(response.data.value.id, {
          name: decrypt(response.data.value.firstName) + ' ' + decrypt(response.data.value.lastName),
          email: decrypt(response.data.value.email),
        });

        SegmentService.track('Logged In', {
          method: 'Google',
        });

        if (callback && typeof callback === 'function') {
          callback();
        } else if (location) {
          navigate(location);
        } else {
          navigate('/dashboard');
        }
      } else {
        toast.error(t('log_in_google_error'));
      }
    });
  };

  const errorMessage = () => {
    toast.error(t('log_in_google_error'));
  };

  return (
    <Box ref={googleLoginParentRef} style={{ width: '100%', alignContent: 'center', margin: 'auto' }}>
      <GoogleLogin
        width={googleLoginWidth}
        onSuccess={responseMessage}
        onError={errorMessage}
        locale={i18n.language.toLowerCase()}
        text={isRegister ? 'signup_with' : 'continue_with'}
        shape='pill'
      />
    </Box>
  );
}

export default LoginGoogle;
