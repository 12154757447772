import axios from 'axios';

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/api',
  withCredentials: true,
  headers: {
    'Content-type': 'application/json',
    // No cache in browser, cache is handled in server
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
});
