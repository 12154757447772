import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
  Button,
  DialogActions,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Close as CloseIcon, Done as DoneIcon, Edit as EditIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Header from '../Header';
import { Sidebar } from './Sidebar';
import Footer from '../Footer';
import { PartnershipForm } from './partnership/PartnershipForm';
import PartnershipDataService from '../../services/partnership.service';
import DisplayImageOrText from '../../features/admin/components/DisplayImageOrText';
import { prepareFormData } from '../../utils/form.utils';

const ConfirmationDialog = React.lazy(() =>
  import('../ConfirmationDialog').then((module) => ({ default: module.default })),
);
const Dialog = React.lazy(() => import('../Dialog').then((module) => ({ default: module.default })));

export function Partnerships(props) {
  const { t } = useTranslation();
  const { items, adminItems, active } = props;

  const [partnerships, setPartnerships] = useState([]);
  const [itemId, setItemId] = useState(null);
  const [open, setOpen] = useState({ edit: false, create: false, delete: false });
  const [responseError, setResponseError] = useState(null);

  const refreshPartnerships = () => {
    PartnershipDataService.getAll().then((res) => {
      setPartnerships(res.data);
    });
  };

  useEffect(() => {
    refreshPartnerships();
  }, []);

  const handleModal = (type) => {
    open[type] = !open[type];
    setResponseError(null);
    setOpen({ ...open });
  };

  const createPartnership = (formData) => {
    setResponseError(null);
    const preparedData = prepareFormData(formData);

    PartnershipDataService.create(preparedData)
      .then(() => {
        refreshPartnerships();
        handleModal('create');
      })
      .catch((error) => {
        setResponseError(error.response?.data?.message);
      });
  };

  const editPartnership = (formData) => {
    setResponseError(null);
    const preparedData = prepareFormData(formData);

    PartnershipDataService.update(formData.id, preparedData)
      .then(() => {
        refreshPartnerships();
        handleModal('edit');
      })
      .catch((error) => {
        setResponseError(error.response?.data?.message);
      });
  };

  const deletePartnership = () => {
    setResponseError(null);
    PartnershipDataService.delete(itemId)
      .then(() => {
        refreshPartnerships();
        setItemId(null);
        handleModal('delete');
      })
      .catch((error) => {
        setResponseError(error.response?.data?.message);
      });
  };

  const content =
    partnerships.length === 0 ? (
      <h2 style={{ textAlign: 'center' }}>{t('partnerships.no_partner')}</h2>
    ) : (
      <TableContainer>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              <TableCell>{t('name')}</TableCell>
              <TableCell>{t('description')}</TableCell>
              <TableCell>{t('partnerships.contact_email')}</TableCell>
              <TableCell>{t('partnerships.website_url')}</TableCell>
              <TableCell>{t('partnerships.logo_url')}</TableCell>
              <TableCell>{t('partnerships.sticker_url')}</TableCell>
              <TableCell>{t('partnerships.associated_projects')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {partnerships.map((partnership) => (
              <TableRow hover key={partnership.id}>
                <TableCell>{partnership.name}</TableCell>
                <TableCell>{partnership.description}</TableCell>
                <TableCell>{partnership.contact_email}</TableCell>
                <TableCell>
                  <a href={partnership.website} target='_blank' rel='noopener noreferrer'>
                    {partnership.website}
                  </a>
                </TableCell>
                <TableCell>
                  <DisplayImageOrText value={partnership.logo || ''} />
                </TableCell>
                <TableCell>
                  <DisplayImageOrText value={partnership.sticker || ''} />
                </TableCell>
                <TableCell>
                  {partnership.projects.map((project) => (
                    <div key={project.id}>{project.title}</div>
                  ))}
                </TableCell>
                <TableCell>
                  <div style={{ display: 'flex' }}>
                    <IconButton
                      onClick={() => {
                        setItemId(partnership.id);
                        handleModal('edit');
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setItemId(partnership.id);
                        handleModal('delete');
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
      <Header items={items} active={active} />

      <React.Suspense fallback={null}>
        <ConfirmationDialog
          open={open.delete}
          handleModal={handleModal}
          title={t('confirmation')}
          handleModalArgs={['delete']}
          text={t('partnerships.deletion_confirmation_question')}
          action={
            <DialogActions>
              <Button onClick={deletePartnership} startIcon={<DoneIcon />}>
                {t('yes')}
              </Button>
              <Button onClick={() => handleModal('delete')} startIcon={<CloseIcon />}>
                {t('no')}
              </Button>
            </DialogActions>
          }
        />
      </React.Suspense>

      <React.Suspense fallback={null}>
        <Dialog
          open={open.create}
          handleModal={handleModal}
          title={t('partnerships.add')}
          content={
            <PartnershipForm
              item={{
                name: '',
                description: '',
                logo: '',
                website: '',
                contact_email: '',
                projects: [],
              }}
              handleForm={createPartnership}
              submitValue={t('add')}
              responseError={responseError}
            />
          }
          handleModalArgs={['create']}
        />
      </React.Suspense>

      <React.Suspense fallback={null}>
        <Dialog
          open={open.edit}
          handleModal={handleModal}
          title={t('partnerships.edit')}
          handleModalArgs={['edit']}
          content={
            <PartnershipForm
              item={partnerships.find((partnership) => partnership.id === itemId) || {}}
              handleForm={editPartnership}
              submitValue={t('edit')}
              responseError={responseError}
            />
          }
        />
      </React.Suspense>

      <section style={{ display: 'flex', flex: '1' }}>
        <Sidebar items={adminItems} active={active} />
        <div style={{ width: '100%', margin: '30px 0' }}>
          <Button onClick={() => handleModal('create')} startIcon={<AddIcon />} style={{ margin: '0 20px' }}>
            {t('partnerships.add')}
          </Button>
          {content}
        </div>
      </section>

      <Footer />
    </div>
  );
}
