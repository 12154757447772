export const prepareFormData = (data, options = {}) => {
  const { fieldsToIgnore = [], isEdit = false } = options;

  // Créer un nouvel objet FormData
  const formData = new FormData();

  // Traiter chaque clé de l'objet "data"
  Object.keys(data).forEach((key) => {
    if (fieldsToIgnore.includes(key)) return; // Ignorer certains champs

    const value = data[key];
    if (value === null || value === undefined) {
      // Si la valeur est nulle ou indéfinie, ajoutez une chaîne vide
      formData.append(key, '');
    } else if (value instanceof File) {
      // Ajouter les fichiers directement
      formData.append(key, value);
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      // Si la valeur est un objet (ex : multilingue)
      Object.keys(value || {}).forEach((subKey) => {
        const subValue = value[subKey];
        formData.append(`${key}[${subKey}]`, subValue ?? ''); // Gérer les sous-valeurs nulles ou undefined
      });
    } else if (Array.isArray(value)) {
      // Si la valeur est un tableau (ex : projets associés)
      value.forEach((item, index) => {
        formData.append(`${key}[${index}]`, JSON.stringify(item));
      });
    } else {
      // Ajouter les champs simples
      formData.append(key, value);
    }
  });

  // Ajouter un champ `id` si c'est une modification
  if (isEdit && data.id) {
    formData.set('id', data.id);
  }

  return formData;
};
