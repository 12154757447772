import React, { Suspense, useContext, useEffect } from 'react';
import ProjectDataService from '../../../services/project.service';
import { Alert, Button, DialogActions, Grid } from '@mui/material';
import ProjectStats from './ProjectStats';
import { Loading } from '../../Loading';
import ProjectRewards from './ProjectRewards';
import { ProjectTodo } from './ProjectTodo';
import { ProjectLabels } from './ProjectLabels';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CodeIcon from '@mui/icons-material/Code';
import HeadingNavBar from '../HeadingNavBar';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../uses/users.context';
import { toast } from 'react-toastify';
import { Dialog } from '../../Dialog';
import ProjectDashboardWidget from '../../../features/dashboard/components/ProjectDashboardWidget';

const ConfirmationDialog = React.lazy(() =>
  import('../../ConfirmationDialog').then((module) => ({ default: module.default })),
);

function DashboardProject(props) {
  const [projectData, setProjectData] = React.useState(null);
  const { user } = useContext(UserContext);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openWidgetPopup, setOpenWidgetPopup] = React.useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  function retrieveProject(projectId) {
    ProjectDataService.get(projectId).then((res) => {
      setProjectData(res.data);
    });
  }

  function deleteProject() {
    ProjectDataService.delete(props.projectId).then(() => {
      toast.error(t('success.project.deleted'));
      navigate('/dashboard/projects');
    });
  }

  useEffect(() => {
    retrieveProject(props.projectId);
  }, [props.projectId]);

  if (!projectData) return <Loading />;

  return (
    <>
      <HeadingNavBar
        backPath={`/dashboard/projects`}
        actions={[
          {
            name: 'show_project',
            icon: <VisibilityIcon />,
            handler: () => navigate(`/project/${props.projectId}`),
            className: 'btn-primary',
          },
          {
            name: 'edit_project',
            icon: <EditIcon />,
            handler: () => navigate(`/dashboard/projects/${props.projectId}/edit`),
            className: 'btn-primary',
          },
          {
            name: 'widget',
            icon: <CodeIcon />,
            handler: () => setOpenWidgetPopup(true),
            className: 'btn-grey',
          },
          {
            name: 'delete_project',
            icon: <DeleteIcon />,
            handler: () => setOpenDeleteDialog(true),
            className: 'btn-grey',
          },
        ]}
      />
      <Dialog
        content={<ProjectDashboardWidget projectSlug={projectData.slug} />}
        open={openWidgetPopup}
        handleModal={() => setOpenWidgetPopup(false)}
        handleModalArgs={[]}
      />
      <Suspense fallback={null}>
        <ConfirmationDialog
          open={openDeleteDialog}
          handleModal={() => setOpenDeleteDialog(false)}
          title={t('project_deletion_confirmation_question')}
          text={t('project_deletion_confirmation_description')}
          action={
            <DialogActions>
              <Button onClick={() => setOpenDeleteDialog(false)} className='btn-grey'>
                {t('cancel')}
              </Button>
              <Button onClick={() => deleteProject()} className='btn-primary'>
                {t('project_deletion_confirm_button')}
              </Button>
            </DialogActions>
          }
        />
      </Suspense>
      <Grid container direction='row' justifyContent='center' sx={{ p: 3 }}>
        {user.kycStatus !== 'VALIDATED' && (
          <Grid item xs={12} mb={2}>
            <Alert severity='warning' style={{ margin: '10px 0 0 0' }}>
              {t('project_kyc_warning')}
            </Alert>
          </Grid>
        )}

        {/* If not booster, add some tips why take booster with link to payment */}
        {/* TODO: Removed 31/01/2025 because now we are handling forfait subscription during the first call */}
        {/*{(!projectData.forfait || projectData.forfait.id === 1) && (*/}
        {/*  <Grid item xs={12} mb={2}>*/}
        {/*    <Alert severity="info" style={{ margin: '10px 0 0 0' }} action={*/}
        {/*      <Button color="inherit" className='btn-primary' style={{ margin: 'auto' }} onClick={() => navigate(`/dashboard/projects/${props.projectId}/forfait`)} endIcon={<RocketLaunchIcon />}>*/}
        {/*        {t('explore_forfaits')}*/}
        {/*      </Button>*/}
        {/*    }>*/}
        {/*      <AlertTitle>*/}
        {/*        <strong>{t('project_no_package')}</strong>*/}
        {/*      </AlertTitle>*/}
        {/*      <p>{t('project_no_package_description')}</p>*/}
        {/*    </Alert>*/}
        {/*  </Grid>*/}
        {/*)}*/}

        <Grid item xs={12} sx={{ textAlign: 'left' }}>
          <Grid container alignItems={'center'}>
            <Grid item>
              <h1 style={{ margin: 0, padding: 0, fontSize: '2rem' }}>{projectData.title}</h1>
            </Grid>
          </Grid>
          <ProjectLabels project={projectData} />
        </Grid>
        <Grid container item direction='row' spacing={5} marginTop={2}>
          <ProjectTodo projectId={props.projectId} />
          <ProjectStats projectId={props.projectId} projectGoalType={projectData.goalType} />
          <ProjectRewards projectId={props.projectId} />
        </Grid>
      </Grid>
    </>
  );
}

export default DashboardProject;
