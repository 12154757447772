import { useTranslation } from 'react-i18next';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import {
  Button,
  DialogActions,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Close as CloseIcon, Done as DoneIcon, Edit as EditIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import PresentationFieldDataService from '../../services/presentationField.service';
import Header from '../Header';
import { Loading } from '../Loading';
import { Sidebar } from './Sidebar';
import Footer from '../Footer';
import { themeContext } from '../../uses/theme.uses';
import { PresentationFieldForm } from './presentationField/PresentationFieldForm';
import GenerateMultiLangField from '../../features/admin/components/GenerateMultiLangField';

// Lazy Loading component
const ConfirmationDialog = React.lazy(() =>
  import('../ConfirmationDialog').then((module) => ({ default: module.default })),
);
const Dialog = React.lazy(() => import('../Dialog').then((module) => ({ default: module.default })));

export function PresentationFields(props) {
  const { t } = useTranslation();
  const { items, adminItems, active } = props;
  const { brandId } = useContext(themeContext);
  const [presentationFields, setPresentationFields] = useState([]);
  const [itemId, setItemId] = useState(null);
  const [open, setOpen] = useState({ edit: false, create: false, delete: false });
  const [responseError, setResponseError] = useState(null);

  useEffect(() => {
    PresentationFieldDataService.getAllByBrand(brandId).then((res) => {
      if ('type' in res.data && res.data.type === 'success') {
        setPresentationFields(res.data.value);
      }
    });
  }, [brandId]);

  const handleModal = (type) => {
    open[type] = !open[type];
    setResponseError(null);
    setOpen({ ...open });
  };

  const createPresentationField = (data) => {
    setResponseError(null);
    PresentationFieldDataService.create(data).then((res) => {
      if ('type' in res.data && res.data.type === 'success') {
        setPresentationFields([...presentationFields, res.data.value]);
        handleModal('create');
      } else if ('type' in res.data && res.data.type === 'error') {
        setResponseError(res.data.value);
      }
    });
  };

  const editPresentationField = async (item) => {
    setResponseError(null);
    PresentationFieldDataService.update(item.id, item).then((res) => {
      if ('type' in res.data && res.data.type === 'success') {
        const index = presentationFields.findIndex((field) => field.id === item.id);
        presentationFields[index] = res.data.value;
        setPresentationFields([...presentationFields]);
        handleModal('edit');
      } else if ('type' in res.data && res.data.type === 'error') {
        setResponseError(res.data.value);
      }
    });
  };

  const deletePresentationField = () => {
    setResponseError(null);
    PresentationFieldDataService.delete(itemId).then((res) => {
      if ('type' in res.data && res.data.type === 'success') {
        const index = presentationFields.findIndex((field) => field.id === itemId);
        presentationFields[index].is_available = false;
        setPresentationFields([...presentationFields]);
        setItemId(null);
        handleModal('delete');
      } else if ('type' in res.data && res.data.type === 'error') {
        setResponseError(res.data.value);
      }
    });
  };

  const content =
    presentationFields.length === 0 ? (
      <h2 style={{ textAlign: 'center' }}>{t('no_presentationFields')}</h2>
    ) : (
      <TableContainer>
        <Table stickyHeader aria-label='sticky table' className='projectList'>
          <TableHead>
            <TableRow>
              <TableCell>{t('name')}</TableCell>
              <TableCell>{t('label')}</TableCell>
              <TableCell>{t('icon')}</TableCell>
              <TableCell>{t('is_available')}</TableCell>
              <TableCell>{t('data_order')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {presentationFields.map((field) => (
              <TableRow hover role='checkbox' className='row' tabIndex={-1} key={field.id}>
                <TableCell>{field.name}</TableCell>
                <TableCell>
                  <GenerateMultiLangField data={field.label} />
                </TableCell>
                <TableCell>{field.icon && <Icon>{field.icon}</Icon>}</TableCell>
                <TableCell>{t(field.is_available.toString())}</TableCell>
                <TableCell>{parseInt(field.data_order)}</TableCell>
                <TableCell style={{ width: '1%', whiteSpace: 'nowrap' }}>
                  <div className='action' style={{ display: 'flex', flexDirection: 'row' }}>
                    <IconButton
                      onClick={() => {
                        setItemId(field.id);
                        handleModal('edit');
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setItemId(field.id);
                        handleModal('delete');
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
      <Header items={items} active={active} />

      {/* Item deletion confirmation */}
      <Suspense fallback={null}>
        <ConfirmationDialog
          open={open.delete}
          handleModal={handleModal}
          title={t('confirmation')}
          handleModalArgs={['delete']}
          text={t('presentation_field_deletion_confirmation_question')}
          action={
            <DialogActions>
              <Button className='btn-primary' onClick={() => deletePresentationField()} startIcon={<DoneIcon />}>
                {t('yes')}
              </Button>
              <Button onClick={() => handleModal('delete')} startIcon={<CloseIcon />}>
                {t('no')}
              </Button>
            </DialogActions>
          }
        />
      </Suspense>

      {/* Modal to add presentation field */}
      <Suspense fallback={null}>
        <Dialog
          open={open.create}
          handleModal={handleModal}
          title={t('add_presentation_field')}
          content={
            <PresentationFieldForm
              responseError={responseError}
              item={{
                brandId,
                name: '',
                label: { fr: '', en: '' },
                icon: '',
                base_content: '',
                is_available: true,
                data_order: -1,
              }}
              handleForm={createPresentationField}
              submitValue={t('add')}
            />
          }
          handleModalArgs={['create']}
        />
      </Suspense>

      {/* Modal to edit presentation field */}
      <Suspense fallback={<Loading />}>
        <Dialog
          open={open.edit}
          handleModal={handleModal}
          title={t('edit_presentation_field')}
          handleModalArgs={['edit']}
          content={
            <PresentationFieldForm
              responseError={responseError}
              submitValue={t('edit')}
              handleForm={editPresentationField}
              item={presentationFields.find((node) => node.id === itemId)}
            />
          }
        />
      </Suspense>

      <section style={{ display: 'flex', flex: '1' }}>
        <Sidebar items={adminItems} active={active} />
        <div style={{ width: '100%', margin: '30px 0' }}>
          <Button
            className='btn-primary'
            onClick={() => {
              handleModal('create');
            }}
            startIcon={<AddIcon />}
            style={{ margin: '0 20px' }}
          >
            {t('add_presentation_field')}
          </Button>
          {content}
        </div>
      </section>

      <Footer />
    </div>
  );
}
