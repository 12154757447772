import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  OutlinedInput,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProjectDataService from '../../../services/project.service';
import FileInputField from '../../../features/admin/components/FileInputField';

export function PartnershipForm({ item, handleForm, submitValue, responseError }) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(item);
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState(
    item.projects?.map((project) => ({
      id: project.id,
      slug: project.slug,
      title: project.title,
      project_partnership: project.project_partnership || {
        contribution_amount: 0,
        double_donation: false,
        additional_info: '',
      },
    })) || [],
  );

  // Récupération des projets disponibles
  useEffect(() => {
    ProjectDataService.getAll().then((res) => {
      setProjects(res.data);
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleProjectChange = (event) => {
    const selectedIds = event.target.value;
    const updatedProjects = selectedIds.map((id) => {
      const existingProject = selectedProjects.find((p) => p.id === id);
      const fullProject = projects.find((p) => p.id === id);

      return (
        existingProject || {
          id: fullProject.id,
          slug: fullProject.slug,
          title: fullProject.title,
          project_partnership: {
            contribution_amount: 0,
            double_donation: false,
            additional_info: '',
          },
        }
      );
    });
    setSelectedProjects(updatedProjects);
  };

  const handleProjectFieldChange = (projectId, field, value) => {
    setSelectedProjects((prevProjects) =>
      prevProjects.map((project) =>
        project.id === projectId
          ? {
              ...project,
              project_partnership: {
                ...project.project_partnership,
                [field]: value,
              },
            }
          : project,
      ),
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleForm({ ...formData, projects: selectedProjects });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: 1 }}>
        {responseError && <div style={{ color: 'red' }}>{responseError}</div>}

        <TextField label={t('partnerships')} name='name' value={formData.name || ''} onChange={handleChange} required />
        <TextField
          label={t('partnerships.description')}
          name='description'
          value={formData.description || ''}
          onChange={handleChange}
          multiline
          rows={3}
        />
        <Typography variant='subtitle2'>{t('partnerships.logo_url')}</Typography>
        <FileInputField onChange={(file) => setFormData({ ...formData, logo: file })} value={formData.logo || ''} />
        <Typography variant='subtitle2'>{t('partnerships.sticker_url')}</Typography>
        <FileInputField
          onChange={(file) => setFormData({ ...formData, sticker: file })}
          value={formData.sticker || ''}
        />
        <TextField
          label={t('partnerships.website_url')}
          name='website'
          value={formData.website || ''}
          onChange={handleChange}
        />
        <TextField
          label={t('partnerships.contact_email')}
          name='contact_email'
          value={formData.contact_email || ''}
          onChange={handleChange}
          type='email'
        />

        <FormControl>
          <InputLabel id='project-select-label'>{t('partnerships.associated_projects')}</InputLabel>
          <Select
            labelId='project-select-label'
            multiple
            value={selectedProjects.map((project) => project.id)}
            onChange={handleProjectChange}
            input={<OutlinedInput id='select-multiple-chip' label={t('partnerships.associated_projects')} />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((id) => {
                  const project = projects.find((p) => p.id === id);
                  return <Chip key={id} label={project?.title || ''} />;
                })}
              </Box>
            )}
          >
            {projects.map((project) => (
              <MenuItem key={project.id} value={project.id}>
                {project.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedProjects.map((project) => (
          <Box key={project.id} sx={{ border: '1px solid #ccc', padding: 2, borderRadius: 2, marginTop: 2 }}>
            <h4>{project.title}</h4>
            <TextField
              label={t('partnerships.contribution_amount')}
              type='number'
              value={project.project_partnership.contribution_amount}
              onChange={(e) =>
                handleProjectFieldChange(project.id, 'contribution_amount', parseFloat(e.target.value) || 0)
              }
            />
            <FormControlLabel
              control={
                <Switch
                  checked={project.project_partnership.double_donation}
                  onChange={(e) => handleProjectFieldChange(project.id, 'double_donation', e.target.checked)}
                />
              }
              label={t('partnerships.double_donation')}
            />
            <TextField
              label={t('partnerships.additional_info')}
              value={project.project_partnership.additional_info}
              onChange={(e) => handleProjectFieldChange(project.id, 'additional_info', e.target.value)}
              multiline
              rows={2}
            />
          </Box>
        ))}

        <Button type='submit' variant='contained'>
          {submitValue}
        </Button>
      </Box>
    </form>
  );
}
