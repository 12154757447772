import React, { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Button, IconButton } from '@mui/material';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { Facebook, LinkedIn, Twitter, ContentCopy, Favorite } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { decrypt } from '../../utils/crypto';
import { Loading } from '../Loading';
import { Link, useNavigate } from 'react-router-dom';
import ProjectCardPlaceholder from '../../assets/images/project-card-placeholder.svg';
import { Textarea } from '@mui/joy';
import CommentService from '../../services/comment.service';
import { toast } from 'react-toastify';
import { UserContext } from '../../uses/users.context';

export default function PaymentSuccess({ project, transaction }) {
  const { t } = useTranslation();
  const [projectUrl, setProjectUrl] = React.useState('');
  const [comment, setComment] = useState(''); // State pour gérer le commentaire
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (project) {
      setProjectUrl(`${window.location.origin}/project/${project.slug}`);
    }
  }, [project]);

  const handleSubmitComment = async () => {
    if (!comment) {
      toast.error('Votre message est vide. Merci de le compléter.');
      return;
    }

    if (!user) {
      toast.error('Vous devez être connecté pour laisser un commentaire.');
      return;
    }

    CommentService.create({
      content: comment,
      userId: user.id,
      projectId: project.id,
      transactionId: transaction.transactionId,
    })
      .then(() => {
        setComment('');
        toast.success('Merci ! Votre message a été pris en compte et apparaîtra bientôt sur la page du projet. 🎉');
      })
      .catch((err) => {
        toast.error("Une erreur est survenue lors de l'envoi de votre commentaire.");
        console.error(err);
      });

    // Redirect to project page
    navigate(`/project/${project.slug}`);
  };

  if (!project || !transaction) {
    return <Loading />;
  }

  return (
    <Grid
      container
      xs={12}
      md={12}
      flexDirection={'column'}
      alignItems={'center'}
      sx={{ marginTop: { xs: 2, md: 10 } }}
    >
      <Grid item xs={12} sx={{ marginBottom: 10 }} textAlign='center'>
        <h1 style={{ lineHeight: '3rem', fontSize: '3rem', marginBottom: '15px' }}>{t('successful_payment')}</h1>
        <p style={{ margin: '0' }}>{t('successful_payment_message', { firstname: decrypt(project.user.firstName) })}</p>
      </Grid>
      <Grid item xs={12} md={6} sx={{ marginBottom: 5, textAlign: 'center' }}>
        <h2 style={{ color: '#054931', marginBottom: 0 }}>Laissez un message au porteur de projet</h2>
        <p style={{ marginTop: 0 }}>Votre message sera visible publiquement sur le projet</p>
        <Textarea
          style={{
            borderRadius: '12px 12px 0 12px',
            padding: '12px',
            width: '100%',
          }}
          minRows={3}
          placeholder={'Félicitation pour ce beau projet 🎉'}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <div
          style={{
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
          }}
        >
          <Link to={'/project/' + project.slug}>
            <Button style={{ margin: '20px 20px 0px' }} className='btn-grey'>
              {t('back_project')}
            </Button>
          </Link>
          <Button style={{ margin: '20px 20px 0px' }} className='btn-primary' onClick={handleSubmitComment}>
            {t('send')}
          </Button>
        </div>
      </Grid>
      <Grid container item xs={12} md={12} spacing={7} direction='row' justifyContent='center' alignItems='flex-start'>
        <Grid item xs={12} md={6} textAlign='center'>
          <h2 style={{ fontWeight: '700', margin: '0', color: '#054931' }}>Partagez le projet !</h2>
          <img
            src={project.image ? project.image : ProjectCardPlaceholder}
            width='100%'
            style={{ display: 'block', margin: '20px 0', height: '300px', objectFit: 'cover', borderRadius: '30px' }}
            alt=''
          />
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            <IconButton
              style={{
                margin: '0 10px 0 0',
                borderRadius: '25px',
                backgroundColor: '#054931',
                width: '42px',
                height: '42px',
              }}
              onClick={() => {
                navigator.clipboard.writeText(projectUrl);
              }}
            >
              <ContentCopy fontSize={'small'} style={{ color: 'white' }} />
            </IconButton>
            <FacebookShareButton
              style={{
                margin: '0 10px 0 0',
                borderRadius: '25px',
                backgroundColor: '#054931',
                width: '42px',
                height: '42px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              url={projectUrl}
              title={t('facebook-button')}
            >
              <Facebook fontSize='small' style={{ color: 'white' }} />
            </FacebookShareButton>
            <TwitterShareButton
              style={{
                margin: '0 10px 0 0',
                borderRadius: '25px',
                backgroundColor: '#054931',
                width: '42px',
                height: '42px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              url={projectUrl}
              title={t('twitter-button')}
            >
              <Twitter fontSize='small' style={{ color: 'white' }} />
            </TwitterShareButton>
            <LinkedinShareButton
              style={{
                margin: '0 10px 0 0',
                borderRadius: '25px',
                backgroundColor: '#054931',
                width: '42px',
                height: '42px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              url={projectUrl}
              title={t('linkedin-button')}
            >
              <LinkedIn fontSize='small' style={{ color: 'white' }} />
            </LinkedinShareButton>
          </div>
        </Grid>
        <Grid
          item
          container
          direction='column'
          xs={12}
          md={6}
          justifyContent='center'
          sx={{ marginTop: { xs: 0, md: 7 } }}
        >
          <Grid
            container
            item
            style={{ backgroundColor: '#e9f4ef' }}
            padding={'0 40px 30px 40px'}
            borderRadius={'20px'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            maxWidth={'500px'}
          >
            <Grid
              item
              style={{
                backgroundColor: '#e9f4ef',
                color: '#006345',
                width: '75px',
                height: '75px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                top: '-35px',
                position: 'relative',
                borderRadius: '50%',
              }}
            >
              <Favorite fontSize={'large'} />
            </Grid>
            <Grid item>
              <h2 style={{ color: '#006345', textAlign: 'center', whiteSpace: 'pre-line', marginTop: '-40px' }}>
                {t('order_message_category', { projectName: project.title })}
              </h2>
            </Grid>
            <Grid item>
              <Link
                to={
                  '/search' +
                  (project.projectCategories.length ? '?category=' + project.projectCategories[0].category.name : '')
                }
              >
                <Button className='btn-primary' style={{ width: '100%', whiteSpace: 'normal' }}>
                  {t('order_message_category_button', {
                    categoryName: project.projectCategories.length
                      ? project.projectCategories[0].category.name
                      : 'similaire',
                  })}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
