import React, { useState, useEffect } from 'react';
import { Button, Box, FormControl, InputLabel, Select, MenuItem, Chip, OutlinedInput } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProjectDataService from '../../../services/project.service';
import MultiLangField from '../../../features/admin/components/MultiLangField';
import MultiLangFileField from '../../../features/admin/components/MultiLangFileField';

export function SDGForm({ item, handleForm, submitValue, responseError }) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(item);
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState(item.projects || []);

  // Récupération des projets disponibles
  useEffect(() => {
    ProjectDataService.getAll().then((res) => {
      setProjects(res.data);
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [field, locale] = name.split('.');
    setFormData({
      ...formData,
      [field]: {
        ...formData[field],
        [locale]: value,
      },
    });
  };

  const handleFileChange = (file, name, locale) => {
    setFormData((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        [locale]: file,
      },
    }));
  };

  const handleProjectChange = (event) => {
    const selectedIds = event.target.value;
    const updatedProjects = selectedIds.map((id) => {
      const existingProject = selectedProjects.find((p) => p.id === id);
      const fullProject = projects.find((p) => p.id === id);

      return (
        existingProject || {
          id: fullProject.id,
          slug: fullProject.slug,
          title: fullProject.title,
        }
      );
    });
    setSelectedProjects(updatedProjects);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleForm({ ...formData, projects: selectedProjects });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: 1 }}>
        {responseError && <div style={{ color: 'red' }}>{responseError}</div>}

        <MultiLangField label={t('name')} name='name' value={formData.name || {}} onChange={handleChange} />

        <MultiLangField
          label={t('description')}
          name='description'
          value={formData.description || {}}
          onChange={handleChange}
        />

        <MultiLangFileField
          label={t('images')}
          name='images'
          value={formData.images || {}}
          onChange={handleFileChange}
        />

        <FormControl>
          <InputLabel id='project-select-label'>{t('sdg.associated_projects')}</InputLabel>
          <Select
            labelId='project-select-label'
            multiple
            value={selectedProjects.map((project) => project.id)}
            onChange={handleProjectChange}
            input={<OutlinedInput id='select-multiple-chip' label={t('sdg.associated_projects')} />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((id) => {
                  const project = projects.find((p) => p.id === id);
                  return <Chip key={id} label={project?.title || ''} />;
                })}
              </Box>
            )}
          >
            {projects.map((project) => (
              <MenuItem key={project.id} value={project.id}>
                {project.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button type='submit' variant='contained'>
          {submitValue}
        </Button>
      </Box>
    </form>
  );
}
