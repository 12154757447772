import React from 'react';
import PropTypes from 'prop-types';
import DisplayImageOrText from './DisplayImageOrText';

const GenerateMultiLangField = ({ data }) => {
  if (!data || Object.keys(data).length === 0) {
    return null;
  }

  const parsedField = typeof data === 'object' ? data : JSON.parse(data);

  return (
    <ul
      style={{
        listStyleType: 'none',
        margin: 0,
        padding: '5px',
      }}
    >
      {Object.keys(parsedField).map((locale, index) => (
        <li key={index}>
          <b>{locale} : </b>
          <DisplayImageOrText value={JSON.stringify(parsedField[locale])} />
        </li>
      ))}
    </ul>
  );
};

GenerateMultiLangField.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

export default GenerateMultiLangField;
