import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TaskAccordion } from './Task/TaskAccordion';
import { UserContext } from '../../uses/users.context';
import UserDataService from '../../services/user.service';
import { decrypt } from '../../utils/crypto';

export function Dashboard() {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUser = async (userId) => {
      try {
        const user = await UserDataService.get(userId);
        setUserData(user.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUser(user.id);
  }, [user]);

  const onboardingTasks = [
    {
      id: 1,
      description: 'Ajoute une photo de profil',
      completed: !!userData?.picture,
      action: {
        label: 'Modifier mon profil',
        url: '/dashboard/account',
      },
    },
    {
      id: 2,
      description: 'Ajoute une description',
      completed: !!userData?.description,
      action: {
        label: 'Modifier mon profil',
        url: '/dashboard/account',
      },
    },
    {
      id: 3,
      description: 'Indique ta date et ton lieu de naissance',
      completed: !!(userData?.birthday && userData?.nationality),
      action: {
        label: 'Modifier mon profil',
        url: '/dashboard/account',
      },
    },
    {
      id: 4,
      description: 'Valide tes pièces justificatives',
      completed: userData?.mangopay_kyc_document_status === 'VALIDATED',
      action: {
        label: 'Envoyer mes pièces justificatives',
        url: '/dashboard/account#verification',
      },
    },
    {
      id: 5,
      description: 'Créer ton premier projet',
      completed: userData?.projects?.length > 0,
      action: {
        label: 'Créer un projet',
        url: '/dashboard/projects?new=true',
      },
    },
    {
      id: 6,
      description: 'Lance ton premier projet',
      completed: userData?.projects?.filter((project) => project.published).length > 0,
      action: {
        label: "Besoin d'aide ?",
        url: '/support',
      },
    },
  ];

  return (
    <Grid container direction='row' justifyContent='center' sx={{ pt: 3 }}>
      <Grid item xs={12}>
        <Typography variant='h4' fontWeight={'bold'} sx={{ mb: 2, fontSize: { xs: '1.5rem', sm: '2.125rem' } }}>
          {t('menu_dashboard_description', { name: decrypt(userData?.firstName) })}
        </Typography>
      </Grid>

      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <TaskAccordion
            title={'Onboarding'}
            completedTasks={onboardingTasks.filter((task) => task.completed).length}
            totalTasks={onboardingTasks.length}
            tasks={onboardingTasks}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
