import React from 'react';
import PropTypes from 'prop-types';

const DisplayImageOrText = ({ value, style }) => {
  const isUrlOrPath = (value) => {
    const urlPattern = /^(https?:\/\/|\/|\.\/|\.\.\/)[^\s]+$/; // Matches URLs or relative paths
    const absolutePathPattern = /^[a-zA-Z]:\\/; // Matches absolute Windows paths
    const blobPattern = /^blob:/; // Matches blob URLs
    return urlPattern.test(value) || absolutePathPattern.test(value) || blobPattern.test(value);
  };

  const getFullPath = (value) => {
    if (value.startsWith('/') || value.startsWith('./') || value.startsWith('../')) {
      return `${process.env.REACT_APP_API_URL}${value}`;
    }
    return value;
  };

  const url = value instanceof File ? URL.createObjectURL(value) : getFullPath(value);

  return (
    <>
      {isUrlOrPath(url) ? (
        <img
          src={url}
          alt='Resource'
          style={{ maxWidth: '100px', maxHeight: '100px', display: 'block', marginTop: '5px', ...style }}
        />
      ) : (
        <span>{value}</span>
      )}
    </>
  );
};

DisplayImageOrText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default DisplayImageOrText;
